import React, { useState } from "react";
import Modal from "react-modal";
import Button from "../utils/Button";
import { customModalStyles } from "./customModalStyles";

const TransactionPasswordModal = ({
  isOpen,
  onRequestClose,
  onSubmit,
  setIsTransPassModalOpen,
  loadingStickers,
}) => {
  const [transPassword, setTransPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(transPassword);
    setTransPassword("");
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customModalStyles}
      onRequestClose={onRequestClose}
      contentLabel="Transaction Password Modal"
      ariaHideApp={false}
    >
      <h2>Enter Transaction Password</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          value={transPassword}
          onChange={(e) => setTransPassword(e.target.value)}
          placeholder="Transaction Password"
          required
        />
        <div className="bton-container">
          <Button type="submit">
            {loadingStickers ? "Submitting..." : "Submit"}
          </Button>
          <Button onClick={() => setIsTransPassModalOpen(false)}>Cancel</Button>
        </div>
      </form>
    </Modal>
  );
};

export default TransactionPasswordModal;
