import React from "react";

import cutomizeMobile from "../../assets/cutomize.png";

import Explore from "./Explore";
import Downbtn from "../DownloadBtn/Downbtn";
const Customize = () => {
  return (
    <div>
      <Explore />
      <section className="hussel" id="features">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mt-5">
              <h2>
                Cusomize your StrinZ <br />
                with its Editor
              </h2>
              <p>
                The editor function in the StrinZ app plays a crucial role in
                facilitating communication by providing users with a versatile
                set of tools for composing, formatting, and enhancing their
                messages. By incorporating features that cater to various
                communication styles and preferences, the editor enhances the
                overall user experience and fosters more engaging and effective
                conversations.
              </p>
              <div>
                <Downbtn />
              </div>
            </div>
            <div className="col-md-6">
              <img
                src={cutomizeMobile}
                className="d-block mx-auto"
                style={{ width: "300px" }}
                alt="BigCo Inc. logo"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Customize;
