import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./sidebar.css";

const SidebarItem = ({ to, icon, text }) => {
  const location = useLocation();

  const isActive = location.pathname === to;

  return (
    <li className={isActive ? "sidebar-linkactive" : "sidebar-link"}>
      <Link to={to} className="sidebar-link">
        {icon}
        <div className={isActive ? "textColor" : "normal"}>
          <span style={{ color: isActive ? "white" : "grey" }}>{text}</span>
        </div>
      </Link>
    </li>
  );
};

export default SidebarItem;
