import React from "react";
import ChangePasswordModal from "./ChangePasswordModal";

const ChangeLoginPasswordModal = ({ isOpen, onClose, userId }) => {
  return (
    <ChangePasswordModal
      isOpen={isOpen}
      onClose={onClose}
      userId={userId}
      title="Login Password"
      passwordField="password"
      confirmField="confirmPassword"
      endpoint="changePassword"
      successMessage="Login password changed successfully!"
      errorMessage="Failed to change login password. Please check your inputs and try again."
    />
  );
};

export default ChangeLoginPasswordModal;
