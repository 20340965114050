// AddPrivacyPolicy.jsx

import React, { useState } from "react";
import "react-quill/dist/quill.snow.css";
import PolicyModal from "./PolicyModal";
import Button from "../utils/Button";
import CustomQuill from "../utils/CustomEditor";
import TransactionPasswordModal from "../utils/TransModal";
import usePrivacyPolicy from "./policiesHook/usePrivacy";
// Import the custom hook

const AddPrivacyPolicy = ({ token, onUpdate, value, setValue }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isTransPassModalOpen, setIsTransPassModalOpen] = useState(false);
  const [loadingTransPass, setLoadingTransPass] = useState(false);

  const { loading, canWrite, handleUpdatePrivacyPolicy, setError } =
    usePrivacyPolicy();

  const handleChange = (content) => {
    setValue(content);
  };

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleTransPassSubmit = async (transPassword) => {
    await handleUpdatePrivacyPolicy(token, value, transPassword, onUpdate);
    setIsTransPassModalOpen(false);
    setLoadingTransPass(false);
  };

  const handleSave = () => {
    if (!value.trim()) {
      setError("Content cannot be empty.");
      return;
    }
    setIsTransPassModalOpen(true);
  };

  return (
    <>
      {canWrite && (
        <Button
          onClick={handleOpenModal}
          disabled={loading}
          className="download"
        >
          Add Policy
        </Button>
      )}
      <PolicyModal isOpen={modalIsOpen} onRequestClose={handleCloseModal}>
        <h2>Add Privacy Policy</h2>
        <CustomQuill value={value} onChange={handleChange} />

        <div className="bton-container">
          <Button onClick={handleSave} disabled={loading} className="download">
            {loading ? "Saving..." : "Save"}
          </Button>
          <Button
            onClick={handleCloseModal}
            disabled={loading}
            className="download"
          >
            Cancel
          </Button>
        </div>
        <TransactionPasswordModal
          isOpen={isTransPassModalOpen}
          setIsTransPassModalOpen={setIsTransPassModalOpen}
          onSubmit={handleTransPassSubmit}
          loading={loadingTransPass}
        />
      </PolicyModal>
    </>
  );
};

export default AddPrivacyPolicy;
