import "./App.css";
import Downbtn from "./components/DownloadBtn/Downbtn";
import ScrollUp from "./components/DownloadBtn/ScrollUp";
import Home from "./components/Home/Home";

import AppRoutes from "./Router/Router";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <Router>
      <AppRoutes />
      <ScrollUp />
    </Router>
  );
}

export default App;
