import React, { useState } from "react";
import ReactQuill from "react-quill";
import PolicyModal from "./PolicyModal";
import Button from "../utils/Button";

const AddFAQ = ({ onAdd }) => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState(false);

  const handleOpenModal = () => {
    setModalIsOpen(true);
    setError(null);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setQuestion("");
    setAnswer("");
  };

  const handleSave = async () => {
    if (question.trim().length === 0 || answer.trim().length === 0) {
      setError("Both question and answer fields are required.");
      return;
    }

    setSaving(true);
    setError(null);
    try {
      await onAdd({ question, answer });
      setQuestion("");
      setAnswer("");
      handleCloseModal();
    } catch (error) {
      console.error("Error adding FAQ:", error);
      setError("Failed to add FAQ. Please try again.");
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <Button onClick={handleOpenModal} className="download">
        Add FAQ
      </Button>
      <PolicyModal isOpen={modalIsOpen} onRequestClose={handleCloseModal}>
        <h2>Add FAQ</h2>
        {error && <div style={{ color: "red" }}>{error}</div>}
        <div>
          <label>Question:</label>
          <ReactQuill value={question} onChange={setQuestion} />
        </div>
        <div>
          <label>Answer:</label>
          <ReactQuill value={answer} onChange={setAnswer} />
        </div>
        <div className="bton-container">
          <Button onClick={handleSave} className="download" disabled={saving}>
            {saving ? "Saving..." : "Save"}
          </Button>
          <Button onClick={handleCloseModal} className="download">
            Cancel
          </Button>
        </div>
      </PolicyModal>
    </>
  );
};

export default AddFAQ;
