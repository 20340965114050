import React from "react";

import withSideBarLayout from "../../sidebarlayout/withSideBarLayout";
import MessageData from "../../messageList/MessageData";

const RelationMessage = () => {
  return <MessageData />;
};

export default withSideBarLayout(RelationMessage);
