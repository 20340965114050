import { useState } from "react";
import { toast } from "react-toastify";
import { usePermission } from "../../utils/useToken";
import { useStickersAPI } from "./useStickerApi";

const useStickies = () => {
  const [stickyType, setStickyType] = useState("");
  const [file, setFile] = useState(null);
  const [selectedStickers, setSelectedStickers] = useState([]);
  const [isTransPassModalOpen, setIsTransPassModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [transPassword, setTransPassword] = useState("");
  const [loadingTransPass, setLoadingTransPass] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);

  const { permission } = usePermission();
  const {
    types,
    stickers,
    loadingTypes,
    loadingStickers,
    getStickersByType,
    uploadSticker,
    deleteStickers,
    activeTab,
    setActiveTab,
  } = useStickersAPI();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleDelete = () => {
    if (selectedStickers.length === 0) {
      toast.error("No stickers selected.");
      return;
    }
    if (
      !window.confirm("Are you sure you want to delete the selected stickers?")
    ) {
      return;
    }
    setPendingAction("delete");
    setIsTransPassModalOpen(true);
  };

  const handleTransPassSubmit = async (transPassword) => {
    setLoadingTransPass(true);
    try {
      if (pendingAction === "upload") {
        await uploadSticker(stickyType, file, transPassword); // Assuming uploadSticker handles transaction password
        closeModal();
      } else if (pendingAction === "delete") {
        const deleted = await deleteStickers(selectedStickers, transPassword); // Assuming deleteStickers handles transaction password
        if (deleted) {
          setSelectedStickers([]);
          getStickersByType(activeTab);
        }
      }
    } catch (error) {
      console.error(`Error during ${pendingAction}:`, error);
      toast.error(
        `${
          pendingAction.charAt(0).toUpperCase() + pendingAction.slice(1)
        } failed: ${error.message}`
      );
    } finally {
      setLoadingTransPass(false);
      setIsTransPassModalOpen(false);
      setPendingAction(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!stickyType.trim() || !file) {
      toast.error("Please select a sticker type and a file.");
      return;
    }
    setPendingAction("upload");
    setIsTransPassModalOpen(true);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setStickyType("");
    setFile(null);
    setIsModalOpen(false);
  };

  const handleTypeChange = (type) => {
    setActiveTab(type);
    getStickersByType(type);
    setSelectedStickers([]);
  };

  const handleCheckboxChange = (stickerUrl) => {
    setSelectedStickers((prevSelected) =>
      prevSelected.includes(stickerUrl)
        ? prevSelected.filter((s) => s !== stickerUrl)
        : [...prevSelected, stickerUrl]
    );
  };

  return {
    stickyType,
    setStickyType,
    file,
    setFile,
    selectedStickers,
    setSelectedStickers,
    activeTab,
    setActiveTab,
    isModalOpen,
    setIsModalOpen,
    types,
    stickers,
    loadingTypes,
    loadingStickers,
    handleFileChange,
    handleDelete,
    handleSubmit,
    openModal,
    closeModal,
    handleTypeChange,
    handleCheckboxChange,
    permission,
    isTransPassModalOpen,
    setIsTransPassModalOpen,
    handleTransPassSubmit,
    transPassword,
    setTransPassword,
    loadingTransPass,
  };
};

export default useStickies;
