export const userInputs = [
  {
    id: 1,
    label: "Username",
    type: "text",
    placeholder: "Enter username",
    name: "userName",
  },
  {
    id: 2,
    label: "Mobile Number",
    type: "tel", // Change type to 'tel'
    placeholder: "Enter mobile number",
    name: "mobileNumber",
  },
  {
    id: 3,
    label: "Password",
    type: "password",
    placeholder: "Enter password",
    name: "password",
  },
  {
    id: 4,
    label: "Transaction Password",
    type: "password",
    placeholder: "Enter transaction password",
    name: "transPass",
  },
  {
    id: 5,
    label: "Confirm Password",
    type: "password",
    placeholder: "Confirm password",
    name: "confirmPassword",
  },
  {
    id: 6,
    label: "Confirm Transaction Password",
    type: "password",
    placeholder: "Confirm transaction password",
    name: "confirmTransPass",
  },
];
