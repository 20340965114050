import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "../Common/Common.css";
import { useScrollToTop } from "../useScrollToTop";

import CommonHeader from "../Common/CommonHeade";
import faqData from "../DATA/faqData";

function FrequentQuestion() {
  useScrollToTop();
  return (
    <div className="wrapper">
      <div className="wrap">
        <CommonHeader children={"FAQ"} />
        <Accordion>
          {faqData.map((item) => (
            <Accordion.Item eventKey={item?.key} key={item?.key}>
              <Accordion.Header>{item?.header}</Accordion.Header>
              <Accordion.Body>{item?.body}</Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  );
}

export default FrequentQuestion;
