import React, { useState } from "react";
import Modal from "react-modal";
import Button from "../utils/Button";
import { customModalStyles } from "../utils/customModalStyles";

const EditRelationshipModal = ({ isOpen, onClose, onUpdate, relationship }) => {
  const [formData, setFormData] = useState({
    id: relationship.Id,
    male: relationship.Male,
    female: relationship.Female,
    other: relationship.Other,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (!formData.other) {
      alert("All fields are required.");
      setIsSubmitting(false);
      return;
    }

    try {
      await onUpdate(formData);
      onClose(); // Close modal after successful update
    } catch (error) {
      alert(`Error: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customModalStyles}
      onRequestClose={onClose}
      contentLabel="Edit Relationship Modal"
    >
      <h3>Edit Relationship</h3>
      <form onSubmit={handleSubmit}>
        <div>
          <label>ID:</label>
          <input
            style={{ color: "grey" }}
            type="text"
            name="id"
            value={formData.id}
            onChange={handleChange}
            disabled
          />
        </div>

        <div>
          <label>Male:</label>
          <input
            type="text"
            name="male"
            value={formData.male}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Female:</label>
          <input
            type="text"
            name="female"
            value={formData.female}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Other:</label>
          <input
            type="text"
            name="other"
            value={formData.other}
            onChange={handleChange}
          />
        </div>
        <div className="bton-container">
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Updating..." : "Update"}
          </Button>
          <Button type="button" onClick={onClose} disabled={isSubmitting}>
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default EditRelationshipModal;
