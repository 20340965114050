import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_API_URL } from "../config/apiURL/Base_Api";
import { useNavigate } from "react-router-dom";
import { useToken } from "../utils/useToken";
import Button from "../utils/Button";

const OtpVerification = () => {
  const [otp, setOtp] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token1 = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const { token } = useToken();

  const handleOtpSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const data = {
      otp: otp,
    };

    try {
      const response = await axios.post(
        `${BASE_API_URL}/verifyOtpFirstLogin`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            auth: `${token1}`,
          },
        }
      );

      if (response.status === 200) {
        alert("OTP verification successful!");
        sessionStorage.setItem("token1", response.data.accessToken);
        sessionStorage.setItem("user", response.data.updatedItem.role);
        sessionStorage.setItem(
          "permission",
          response.data.updatedItem.permission
        );
        navigate("/admin/user/home");
      } else {
        throw new Error("OTP verification failed.");
      }
    } catch (error) {
      console.error("OTP Verification Error:", error);
      alert("OTP verification failed. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    try {
      if (token) {
        navigate("/admin/user/home");
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [token]);
  return (
    <div className="login-wrapper">
      <div className="otp-container">
        <form id="otp-form" onSubmit={handleOtpSubmit}>
          <h2>OTP Verification</h2>
          <div className="input-group">
            <label htmlFor="otp">OTP</label>
            <input
              type="text"
              id="otp"
              name="otp"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
            />
          </div>
          <Button type="submit" disabled={isSubmitting} className="log-button">
            {isSubmitting ? "Verifying OTP..." : "Verify OTP"}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default OtpVerification;
