import React from "react";
import "./CommonHeader.css";
const CommonHeader = ({ children }) => {
  return (
    <div className="c">
      <div className="heading">{children}</div>
    </div>
  );
};

export default CommonHeader;
