import { useState, useEffect } from "react";
import axios from "axios";
import { useToken } from "../utils/useToken";
import { BASE_API_URL } from "../config/apiURL/Base_Api";
import { handle500Error } from "../config/logout500/Handle500";

const useWidgetData = (type, timeRange) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { token } = useToken();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true before fetching

      try {
        let apiUrl = "";

        switch (type) {
          case "emotion":
            apiUrl = `${BASE_API_URL}/dashboard?hours=${getTimeRangeValue(
              timeRange
            )}`;
            break;
          case "sticky":
          case "relation":
            apiUrl = `${BASE_API_URL}/dashboard?hours=${getTimeRangeValue(
              timeRange
            )}`;
            break;
          case "userReport":
            apiUrl = `${BASE_API_URL}/userReport?hours=${getTimeRangeValue(
              timeRange
            )}`;
            break;
          default:
            return;
        }

        const response = await axios.get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            Auth: token,
          },
        });
        if (response.data.status === 401) {
          handle500Error();
          return;
        }
        let widgetData = {};

        switch (type) {
          case "emotion":
            widgetData = {
              title: "Emotions",
              count: response.data.data.emotion.reduce(
                (acc, item) => acc + parseInt(item.count),
                0
              ),
              details: response.data.data.emotion
                .map((item) => `${item.emotion}: ${item.count}`)
                .join(", "),
            };
            break;
          case "sticky":
            widgetData = {
              title: "Stickies",
              count: response.data.data.sticky.reduce(
                (acc, item) => acc + parseInt(item.count),
                0
              ),
              details: response.data.data.sticky
                .map((item) => `${item.sticky}: ${item.count}`)
                .join(", "),
            };
            break;
          case "relation":
            widgetData = {
              title: "Relations",
              count: response.data.data.relation.reduce(
                (acc, item) => acc + parseInt(item.count),
                0
              ),
              details: response.data.data.relation
                .map((item) => `${item.relation_name}: ${item.count}`)
                .join(", "),
            };
            break;
          case "userReport":
            widgetData = {
              title: "Users",
              count:
                response.data.data.created +
                response.data.data.pinCreated +
                response.data.data.deleted,
              details: `Verified: ${response.data.data.created}, Profiles: ${response.data.data.pinCreated}, Deleted: ${response.data.data.deleted}`,
            };
            break;
          default:
            break;
        }

        setData(widgetData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    if (token) {
      fetchData();
    }
  }, [type, timeRange, token]);

  const getTimeRangeValue = (range) => {
    switch (range) {
      case "last24hours":
        return 24;
      case "last7days":
        return 7 * 24;
      case "tilldate":
        const startDate = new Date("2024-07-01");
        const currentDate = new Date();
        const diffInHours = Math.floor(
          (currentDate - startDate) / (1000 * 60 * 60)
        );
        return diffInHours;
      default:
        return 24; // Default to last 24 hours
    }
  };

  return { data, loading };
};

export default useWidgetData;
