import React, { useState, useEffect } from "react";
import "./styling/RelationshipData.css";
import MessageGrid from "./MessageGrid";
import { debounce } from "lodash";
import { MessagePerPage } from "../constants/TotalCount";

const MessageList = ({
  messages,
  handleEditMessage,
  handleDeleteMessage,
  setSelectedMessages,
  loadingMessages,
  setPage,
  count,
  page,
  deletingId,
  permission,
  submitting,
}) => {
  const [filteredMessages, setFilteredMessages] = useState(messages);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMessageId, setSelectedMessageId] = useState(null);

  useEffect(() => {
    setFilteredMessages(messages);
  }, [messages]);

  const debouncedSearch = debounce((value) => {
    setSearchTerm(value);
  }, 300);

  useEffect(() => {
    const filtered = messages.filter((message) =>
      message.message.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredMessages(filtered);
  }, [searchTerm, messages]);

  const handleRadioChange = (messageId) => {
    setSelectedMessageId(messageId);
    setSelectedMessages([messageId]);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    debouncedSearch(value);
  };

  const handlePrevClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextClick = () => {
    if (page < Math.ceil(count / MessagePerPage)) {
      setPage(page + 1);
    }
  };

  return (
    <div>
      <div className="message-list-container">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search messages"
            onChange={handleSearchChange}
          />
        </div>

        <MessageGrid
          messages={filteredMessages}
          selectedMessageId={selectedMessageId}
          handleRadioChange={handleRadioChange}
          handleEditMessage={handleEditMessage}
          handleDeleteMessage={handleDeleteMessage}
          loadingMessages={loadingMessages}
          permission={permission}
          submitting={submitting}
          messagesPerPage={MessagePerPage}
          currentPage={page}
          deletingId={deletingId}
        />
      </div>

      {filteredMessages.length > 0 && (
        <div className="pagination">
          <div
            className={`pagination-button ${
              page === 1 ? "disabled" : "download"
            }`}
            onClick={handlePrevClick}
            disabled={page === 1}
          >
            Prev
          </div>
          <span className="page-info">
            Page {page} / {Math.ceil(count / MessagePerPage)}
          </span>
          <div
            className={`pagination-button ${
              page === Math.ceil(count / MessagePerPage)
                ? "disabled"
                : "download"
            }`}
            onClick={handleNextClick}
            disabled={page === Math.ceil(count / MessagePerPage)}
          >
            Next
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageList;
