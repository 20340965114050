export const EulaData = [
  {
    key: "0",
    body: (
      <>
        {" "}
        <p>
          PLEASE READ THIS END USER SOFTWARE LICENSE AGREEMENT ("LICENSE")
          CAREFULLY BEFORE CLICKING THE "ACCEPT" BUTTON OR DOWNLOADING OR USING
          THE STRINZ MOBILE APPLICATION ("APPLICATION") ACCOMPANYING THIS
          LICENSE. BY CLICKING THE "ACCEPT" BUTTON OR DOWNLOADING OR USING THE
          APPLICATION, YOU ARE ENTERING INTO AND AGREEING TO BE BOUND BY THE
          TERMS OF THIS LICENSE. IF YOU DO NOT AGREE TO THE TERMS OF THIS
          LICENSE, DO NOT CLICK THE "ACCEPT" BUTTON OR DOWNLOAD OR USE THE
          APPLICATION.
        </p>
        <h5>1. General</h5>
        <p>
          The Application is licensed, not sold, to You by ANOVA Consultancies
          FZCO. (the “Company”) for use strictly in accordance with the terms
          and conditions of this License, and any "usage rules" established by
          any other third-party usage rules or terms of use, such as Apple Inc.
          and such other vendors (“Usage Rules”), which are incorporated herein
          by this reference. The term "Application" shall refer to and consist
          of the following:
        </p>
        <p>
          <ul>
            <li>
              The mobile software application accompanying this License,
              including, without limitation, any software code, scripts,
              interfaces, graphics, displays, text, documentation and other
              components;
            </li>
            <li>
              Any updates, modifications or enhancements to the items listed in
              subsection (i); and
            </li>
            <li>
              Any specific website the Application directs you to via any
              browser located on an iPhone or such other mobile device (“Mobile
              Device”).
            </li>
          </ul>
        </p>
        <h5>2. License Grant and Restrictions on Use</h5>
        <br />
        <h6>2.1 License Grant</h6>
        <p>
          Company grants You a revocable, non-exclusive, non-transferable,
          limited right to install and use the Application on a single Mobile
          Device owned and controlled by You, and to access and use the
          Application on such Mobile Device strictly in accordance with the
          terms and conditions of this License, the Usage Rules and any service
          agreement associated with your Mobile Device (collectively "Related
          Agreements").
        </p>
        <h6>2.2 Restrictions on Use</h6>
        <p>
          You shall use the Application strictly in accordance with the terms of
          the Related Agreements and shall not:
        </p>
        <p>
          <ul>
            <li>
              Decompile, reverse engineer, disassemble, attempt to derive the
              source code of, or decrypt the Application;
            </li>
            <li>
              Make any modification, adaptation, improvement, enhancement,
              translation or derivative work from the Application;
            </li>
            <li>
              Violate any applicable laws, rules or regulations in connection
              with Your access or use of the Application;
            </li>
            <li>
              Remove, alter or obscure any proprietary notice (including any
              notice of copyright or trademark) of Company or its affiliates,
              partners, suppliers or the licensors of the Application;
            </li>
            <li>
              Use the Application for any revenue generating endeavor,
              commercial enterprise, or other purpose for which it is not
              designed or intended;
            </li>
            <li>
              Install, use or permit the Application to exist on more than one
              Mobile Device at a time or on any other mobile device or computer;
            </li>
            <li>Distribute the Application to multiple Mobile Devices;</li>
            <li>
              Make the Application available over a network or other environment
              permitting access or use by multiple Mobile Devices or users at
              the same time;
            </li>
            <li>
              Use the Application for creating a product, service or software
              that is, directly or indirectly, competitive with or in any way a
              substitute for any services, product or software offered by
              Company;
            </li>
            <li>
              Use the Application to send automated queries to any website or to
              send any unsolicited commercial e-mail; or
            </li>
            <li>
              Use any proprietary information or interfaces of Company or other
              intellectual property of Company in the design, development,
              manufacture, licensing or distribution of any applications,
              accessories or devices for use with the Application.
            </li>
          </ul>
        </p>
        <h5>3. Intellectual Property Rights</h5>
        <br />
        <h6>3.1 Rights to Application</h6>
        <p>
          You acknowledge and agree that the Application and all copyrights,
          patents, trademarks, trade secrets and other intellectual property
          rights associated therewith are, and shall remain, the property of
          Company. Furthermore, You acknowledge and agree that the source and
          object code of the Applications and the format, directories, queries,
          algorithms, structure and organization of the Application are the
          intellectual property and proprietary and confidential information of
          Company and its affiliates, licensors and suppliers. Except as
          expressly stated in this License, You are not granted any intellectual
          property rights in or to the Application by implication, estoppel or
          other legal theory, and all rights in and to the Application not
          expressly granted in this License are hereby reserved and retained by
          Company.
        </p>
        <h6>3.2 Third Party Software</h6>
        <p>
          The Application may utilize or include third party software that is
          subject to open source and third party license terms ("Third Party
          Software"). You acknowledge and agree that Your right to use such
          Third-Party Software as part of the Application is subject to and
          governed by the terms and conditions of the open source or third-party
          license applicable to such Third-Party Software, including, without
          limitation, any applicable acknowledgements, license terms and
          disclaimers contained therein. In the event of a conflict between the
          terms of this License and the terms of such open source or third-party
          licenses, the terms of the open source or third-party licenses shall
          control with regard to Your use of the relevant Third-Party Software.
          In no event, shall the Application or components thereof be deemed to
          be "open source" or "publically available" software.
        </p>
        <h6>3.3 Company’s Marks</h6>
        <p>
          You are not authorized to use the Company trademarks in any
          advertising, publicity or in any other commercial manner without the
          prior written consent of Company, which may be withheld for any or no
          reason.
        </p>
        <h6>3.4 Infringement Acknowledgement</h6>
        <p>
          You and Company acknowledge and agree that, in the event of a third
          party claim that the Application or Your possession or use of the
          Application infringes any third party's intellectual property rights,
          You (and not Company) will be responsible for the investigation,
          defense, settlement and discharge of any such claim of intellectual
          property infringement. You will, however, promptly notify Company in
          writing of such a claim.
        </p>
        <h5>4. Restriction on Transfer</h5>
        <p>
          You may not rent, lease, lend, sublicense or transfer the Application,
          this License or any of the rights granted hereunder. Any attempted
          transfer in contravention of this provision shall be null and void and
          of no force or effect.
        </p>
        <h5>5. Use of Information</h5>
        <br />
        <h6>5.1 Consent to Use Information</h6>
        <p>
          You hereby authorize and consent to the collection, storage and use,
          by Company and its affiliates, partners and agents, of any information
          and data related to or derived from Your use of the Application, and
          any information or data that You provide to Company and its
          affiliates, partners and licensors ("Information"). Without limiting
          the generality of the foregoing, the Information shall include,
          without limitation, the following types of information and data, in an
          aggregate (not user level) form: search requests, search results,
          patterns, data and suggestions based on user actions. Notwithstanding
          the foregoing, You shall not provide or disclose and the Information
          shall not include any information or data that is personally
          identifiably to You. The Information will be treated as being
          non-confidential and nonproprietary, and Company assumes no obligation
          to protect confidential or proprietary information (other than
          personally identifiable information) from disclosure and will be free
          to reproduce, use, and distribute the Information to others without
          restriction. We will also be free to use any ideas, concepts, know-how
          or techniques contained in the Information for any purpose whatsoever
          including, without limitation, developing, manufacturing and marketing
          products and services incorporating such Information.
        </p>
        <h6>5.2 Privacy Policy</h6>
        <p>
          You represent that You shall comply with the terms and conditions of
          the Company Privacy Policy, which sets forth and describes the
          practices of Company with respect to the collection, use and
          disclosure of Information in connection with Your use of the
          Application. Company reserves the right to change the provisions of
          its Privacy Policy at any time and from time to time at its sole
          discretion. Company will post any changes to its Privacy Policy at the
          web address,{" "}
          <a to="/privacy" target="_blank">
            https://www.strinz.online/privacy
          </a>
          . Your use of the Application following the posting of such changes to
          the Privacy Policy will constitute Your acceptance of any such
          changes.
        </p>
        <h5>6. Third Party Content and Services</h5>
        <br />
        <h6>6.1 General</h6>
        <p>
          You acknowledge that the Application permits access to products,
          services, web-sites, advertisements, promotions, recommendations,
          advice, information, and materials created and provided by
          advertisers, publishers, content partners, marketing agents, vendors
          and other third parties ("Third Party Content and Services").
        </p>
        <h6>6.2 Disclaimer</h6>
        <p>
          You acknowledge that Company does not investigate, monitor, represent
          or endorse the Third-Party Content and Services (including any third
          party websites available through the Application). Furthermore, Your
          access to and use of the Third-Party Content and Services is at Your
          sole discretion and risk, and Company and its affiliates, partners,
          suppliers and licensors shall have no liability to You arising out of
          or in connection with Your access to and use of the Third-Party
          Content and Services. Company hereby disclaims any representation,
          warranty or guaranty regarding the Third-Party Content and Services,
          whether express, implied, or statutory, including without limitation
          merchantability, fitness for a particular purpose, title,
          non-infringement, or the absence of any errors, viruses or other
          harmful components.
        </p>
        <h6>6.3 Third Party Terms of Service</h6>
        <p>
          You acknowledge and agree that Your access to and use of the
          Third-Party Content and Services is subject to the terms, conditions
          and policies applicable to such Third-Party Content and Services,
          including without limitation the privacy and data gathering practices
          of such third parties. You are responsible for complying with any
          applicable third-party terms of service and policies.
        </p>
        <h5>7. Term and Termination</h5>
        <br />
        <h6>7.1 Term</h6>
        <p>This License shall be effective until terminated.</p>
        <h6>7.2 Termination by Company</h6>
        <p>
          Without prejudice to any other rights, this License shall terminate
          automatically if You fail to comply with any of the terms and
          conditions of this License. In such event, no notice shall be required
          by Company to effect such termination.
        </p>
        <h6>7.3 Termination by You</h6>
        <p>
          You may terminate this License at any time by permanently deleting the
          Application in its entirety from Your Mobile Device, whereupon Your
          rights to use the Application shall cease, and You shall cease all use
          of the Application and any related services.
        </p>
        <h6>7.4 Effects of Termination</h6>
        <p>
          Upon termination of this License for any reason, You shall cease all
          use of the Application and any related services.
        </p>
        <h5>8. Disclaimer of Warranties</h5>
        <p>
          YOU ACKNOWLEDGE AND AGREE THAT THE APPLICATION IS PROVIDED ON AN "AS
          IS" AND "AS AVAILABLE" BASIS, AND THAT YOUR USE OF OR RELIANCE UPON
          THE APPLICATION AND ANY THIRD PARTY CONTENT AND SERVICES ACCESSED
          THEREBY IS AT YOUR SOLE RISK AND DISCRETION. COMPANY AND ITS
          AFFILIATES, PARTNERS, SUPPLIERS AND LICENSORS HEREBY DISCLAIM ANY AND
          ALL REPRESENTATIONS, WARRANTIES AND GUARANTIES REGARDING THE
          APPLICATION AND THIRD-PARTY CONTENT AND SERVICES, WHETHER EXPRESS,
          IMPLIED, OR STATUTORY, AND INCLUDING WITHOUT LIMITATION, THE IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT.
        </p>
        <h5>9. Limitation of Liability</h5>
        <p>
          TO THE FULLEST EXTENT PERMITTED BY LAW, UNDER NO CIRCUMSTANCES SHALL
          COMPANY OR ITS AFFILIATES, PARTNERS, SUPPLIERS OR LICENSORS BE LIABLE
          FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR EXEMPLARY
          DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR ACCESS OR USE OF OR
          INABILITY TO ACCESS OR USE THE APPLICATION AND ANY THIRD-PARTY CONTENT
          AND SERVICES, WHETHER OR NOT THE DAMAGES WERE FORESEEABLE AND WHETHER
          OR NOT COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        <h5>10. Indemnification</h5>
        <p>
          You shall indemnify, defend and hold harmless Company and its
          affiliates, partners, suppliers and licensors from and against any and
          all claims, damages, losses, liabilities, costs and expenses
          (including reasonable attorneys’ fees) arising out of or in connection
          with Your use of the Application or any Third Party Content and
          Services, or any breach by You of this License.
        </p>
        <h5>11. Miscellaneous</h5>
        <br />
        <h6>11.1 Governing Law</h6>
        <p>
          This License shall be deemed to take place in the United Arab Emirates
          and shall be governed by and construed in accordance with the laws of
          the United Arab Emirates, excluding its conflicts of law principles.
          Any disputes arising from this License shall be adjudicated in the
          courts of the United Arab Emirates. This License shall not be governed
          by the United Nations Convention on Contracts for the International
          Sale of Goods, the application of which is expressly excluded. In the
          event that any provision of this License is held to be unenforceable,
          such provision shall be limited or eliminated to the minimum extent
          necessary so that this License shall otherwise remain in full force
          and effect and enforceable.
        </p>
        <h6>11.2 Severability</h6>
        <p>
          If any provision of this License is held to be illegal, invalid or
          unenforceable, such provision shall be enforced to the fullest extent
          permitted by applicable law, and the legality, validity and
          enforceability of the remaining provisions of this License shall not
          be affected thereby.
        </p>
        <h6>11.3 Waiver</h6>
        <p>
          A waiver by Company of any provision of this License shall not be
          construed as a waiver of any other provision or any preceding or
          succeeding breach of any provision, and Company’s failure to assert
          any right or provision under this License shall not constitute a
          waiver of such right or provision.
        </p>
        <h6>11.4 Entire Agreement</h6>
        <p>
          This License constitutes the entire agreement between You and Company
          with respect to the use of the Application and supersedes all prior or
          contemporaneous understandings regarding such subject matter. No
          amendment to or modification of this License will be binding unless in
          writing and signed by Company.
        </p>
        <h5>12. Contact Information</h5>
        <p>
          If you have any questions about this EULA, please contact us at{" "}
          <a href="mailto:contact@anovaconsultancies.org">
            contact@anovaconsultancies.org
          </a>
        </p>
      </>
    ),
  },
];
