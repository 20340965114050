const formatMobileNumber = (number) => {
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};

export const userColumns = [
  {
    field: "Username",
    headerName: "Username",
    width: 250,
    renderCell: (params) => {
      return <div className="cellWithImg">{params.row.userName}</div>;
    },
  },
  {
    field: "mobileNumber",
    headerName: "Mobile Number",
    width: 250,
    renderCell: (params) => {
      return (
        <div className="cellWithMobile">
          <span>
            {params.row.countryCode}-{params.row.mobileNumber}
          </span>
        </div>
      );
    },
  },

  {
    field: "Permission",
    headerName: "Permission",
    width: 250,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.permission}
        </div>
      );
    },
  },
];
