import React from "react";
import { useNavigate } from "react-router-dom";
import "./PageNotFound.css";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="page-not-found">
      <h1 className="titl">404</h1>
      <h2 className="subtitl">
        Oops! The page you're looking for doesn't exist.
      </h2>
      <div
        onClick={() => {
          navigate("/admin");
        }}
        className="download"
      >
        Go to Home
      </div>
    </div>
  );
};

export default PageNotFound;
