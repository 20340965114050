// usePrivacyPolicy.js

import { useState } from "react";
import { updatePrivacyPolicyApi } from "../api/privacyApi";
import { usePermission } from "../../utils/useToken";

const usePrivacyPolicy = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { permission } = usePermission();

  const handleUpdatePrivacyPolicy = async (
    token,
    value,
    transpass,
    onUpdate
  ) => {
    setLoading(true);
    setError("");
    try {
      const { error } = await updatePrivacyPolicyApi(token, value, transpass);
      if (!error) {
        onUpdate();
        alert("Privacy updated successfully..");
      } else {
        alert(error);
      }
    } catch (error) {
      console.error("Error updating Privacy Policy:", error);
      setError("Failed to update Privacy Policy. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    canWrite: permission === "write",
    handleUpdatePrivacyPolicy,
    setError,
  };
};

export default usePrivacyPolicy;
