import React from "react";
import secfive from "../../assets/section3.png";
import { Link } from "react-router-dom";
import "./Carousel.css";
import Downbtn from "../DownloadBtn/Downbtn";
const HowItWork = () => {
  return (
    <div>
      <section className="hussel" id="work">
        <div className="container">
          <div className="workContain">
            <div className="col-md-6">
              <h2>
                Send hassle-free StrinZ
                <br />
                in just a few clicks.
              </h2>
              <p>
                Seamless Communication: Enjoy instant message creation based on
                one-to-one relationship and emotion, options to choose your
                message from never ending list of messages, sharing messages
                with your friends, family, and loved ones at one place.
              </p>
              <div>
                <Downbtn />
              </div>
            </div>
            <div className="col-md-6 mt-4 ">
              <img
                src={secfive}
                alt="BigCo Inc. logo"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HowItWork;
