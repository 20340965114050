import React from "react";
import Strinz from "../../imageassets/download.png";
const Cover = () => {
  return (
    <div
      style={{
        width: "30%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={Strinz} alt="strinz" />
    </div>
  );
};

export default Cover;
