import React from "react";

import withSideBarLayout from "../sidebarlayout/withSideBarLayout";
import RelationshipTable from "../relationshipdata/RelationshipTable";

const Relations = () => {
  return <RelationshipTable />;
};

export default withSideBarLayout(Relations);
