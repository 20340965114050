import React from "react";
import "./new.css";
import Sidebar from "../../sidebar/Sidebar";
import useUserManagement from "../../datatable/useUsermanagment";
import { useNavigate } from "react-router-dom";
import UserForm from "./useForm";
import Instruction from "../../utils/Instruction";

const New = ({ inputs, title }) => {
  const { fetchData } = useUserManagement();
  const navigate = useNavigate();

  return (
    <div className="new">
      <div style={{ width: "20%" }}>
        <Sidebar />
      </div>
      <div className="newContainer">
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left"></div>
          <div className="right">
            <UserForm
              inputs={inputs}
              fetchData={fetchData}
              navigate={navigate}
            />
          </div>
        </div>
        <Instruction />
      </div>
    </div>
  );
};

export default New;
