import * as Yup from "yup";

export const validationSchema = Yup.object({
  userName: Yup.string().required("Username is required"),
  mobileNumber: Yup.string().required("Mobile Number is required"),
  countryCode: Yup.string().required("Country Code is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/^[A-Za-z0-9]+$/, "Password must not contain special characters")
    .notOneOf(
      [Yup.ref("transPass")],
      "Password cannot be the same as Transaction Password"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  transPass: Yup.string()
    .min(8, "Transaction Password must be at least 8 characters long")
    .matches(
      /[A-Z]/,
      "Transaction Password must contain at least one uppercase letter"
    )
    .matches(
      /^[A-Za-z0-9]+$/,
      "Transaction Password must not contain special characters"
    )
    .notOneOf(
      [Yup.ref("password")],
      "Transaction Password cannot be the same as Password"
    )
    .required("Transaction Password is required"),
  confirmTransPass: Yup.string()
    .oneOf([Yup.ref("transPass"), null], "Transaction Passwords must match")
    .required("Confirm Transaction Password is required"),
  permission: Yup.string()
    .oneOf(["read", "write"], "Invalid permission type")
    .required("Permission is required"),
});
