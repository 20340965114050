import React from "react";
import Sidebar from "../../sidebar/Sidebar";
import Stickers from "../../stickys/Stickers";
const StickyPage = () => {
  return (
    <div className="list">
      <div style={{ width: "18%" }}>
        <Sidebar />
      </div>
      <div className="listContainer">
        <Stickers />
      </div>
    </div>
  );
};

export default StickyPage;
