import { useState, useEffect } from "react";

export const useToken = () => {
  const [token, setToken] = useState("");

  useEffect(() => {
    const storedToken = sessionStorage.getItem("token1");

    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  return { token };
};
export const useRole = () => {
  const [user, setUser] = useState("");
  useEffect(() => {
    const storedUser = sessionStorage.getItem("user");

    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  return { user };
};
export const usePermission = () => {
  const [permission, setPermission] = useState("");
  useEffect(() => {
    const storedPermission = sessionStorage.getItem("permission");

    if (storedPermission) {
      setPermission(storedPermission);
    }
  }, []);

  return { permission };
};
