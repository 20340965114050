// useEULA.js

import { useState } from "react";
import { updateEULA } from "../api/eulaApi";
import { usePermission } from "../../utils/useToken";

const useEULA = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { permission } = usePermission();

  const handleUpdateEULA = async (token, value, transpass, onUpdate) => {
    setLoading(true);
    setError("");
    try {
      const { error } = await updateEULA(token, value, transpass);
      if (!error) {
        alert("Eula updated successfully..");
        onUpdate();
      } else {
        alert(error);
      }
    } catch (error) {
      console.error("Error updating EULA:", error);
      alert("Failed to update EULA. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    canWrite: permission === "write",
    handleUpdateEULA,
    setError,
  };
};

export default useEULA;
