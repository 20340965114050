// TermsAndConditionsApi.js
import axios from "axios";
import { BASE_API_URL } from "../../config/apiURL/Base_Api";
import { handle500Error } from "../../config/logout500/Handle500";

export const fetchTermsAndConditionsApi = async (token) => {
  try {
    const response = await axios.get(`${BASE_API_URL}/getTermsAndConditions`, {
      headers: {
        Auth: token,
      },
    });
    if (response.data.status === 401) {
      handle500Error();
      return { error: "Internal Server Error" };
    }
    if (response.data && response.data.data) {
      return { value: response.data.data };
    } else {
      return { error: "No terms and conditions data found." };
    }
  } catch (error) {
    console.error("Error fetching terms and conditions:", error);
    return { error: "Failed to fetch terms and conditions. Please try again." };
  }
};

export const updateTermsAndConditionsApi = async (
  token,
  value,
  transPassword
) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}/updateTermsAndConditions`,
      { content: value, transPass: transPassword },
      {
        headers: {
          Auth: token,
        },
      }
    );

    if (response.data.status === 200) {
      return { success: true };
    }
    if (response.data.success !== 200) {
      return { error: "Invalid Password." };
    }
    if (response.data.status === 401) {
      handle500Error();
      return { error: "Internal Server Error" };
    }
    return { error: "An unexpected error occurred." };
  } catch (error) {
    console.error("Error updating terms and conditions:", error);
    return {
      error: "Failed to update terms and conditions. Please try again.",
    };
  }
};
