import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useToken } from "../../utils/useToken";
import { BASE_API_URL } from "../../config/apiURL/Base_Api";
import { handle500Error } from "../../config/logout500/Handle500";
const useSingleUser = () => {
  const { id } = useParams();
  const { token } = useToken();

  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [newPermission, setNewPermission] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(
          `${BASE_API_URL}/getSingleUser/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Auth: token,
            },
          }
        );
        if (response.data.status === 500) {
          handle500Error();
          return;
        }
        if (response.data.status === 200) {
          setUser(response.data.data);
          setLoading(false);
        }
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    if (token) {
      fetchUser();
    }
  }, [id, token]);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = async () => {
    try {
      const response = await axios.post(
        `${BASE_API_URL}/updateSingleUser`,
        { id: id, permission: newPermission },
        {
          headers: {
            "Content-Type": "application/json",
            Auth: token,
          },
        }
      );
      if (response.data.status === 500) {
        handle500Error();
        return;
      }
      if (response.data.status === 200) {
        alert("user updated successfully.");
        setUser((prevUser) => ({
          ...prevUser,
          permission: newPermission, // Assuming the response updates the permission
        }));
        navigate("/admin/user/users");
        setEditMode(false);
      }
    } catch (error) {
      console.error("Error updating permission:", error);
    }
  };

  const handleCancel = () => {
    setEditMode(false);
    // Optionally, reset newPermission state if needed
    setNewPermission("");
  };

  const handleChangePermission = (value) => {
    setNewPermission(value);
  };

  return {
    user,
    loading,
    error,
    editMode,
    newPermission,
    handleEdit,
    handleSave,
    handleCancel,
    handleChangePermission,
  };
};

export default useSingleUser;
