const faqData = [
  {
    key: "0",
    header: "How Can I Download & Install StrinZ App on my Android phone?",
    body: (
      <>
        <ul>
          <li>
            Go to the Google Play Store, search for StrinZ app. Tap INSTALL.
          </li>{" "}
          <br />
          <li>
            Open StrinZ and get started after “Verify” your phone number.
          </li>{" "}
          <br />
          <li>To continue, Create your Profile.</li>
        </ul>
        <strong>Note:</strong> User can also change this later by tapping &gt;
        Settings &gt; Edit Profile.
      </>
    ),
  },
  {
    key: "1",
    header: "How Can I uninstall StrinZ App from my Android phone?",
    body: (
      <>
        <ul>
          <li>Go to your device's Settings</li> <br />
          <li>Tap Apps & notifications</li> <br />
          <li>Search & Select StrinZ</li> <br />
          <li>Uninstall to remove the app and all its data.</li>
        </ul>
      </>
    ),
  },
  {
    key: "2",
    header: "How Can I Download & Install StrinZ App on my IOS phone?",
    body: (
      <>
        <ul>
          <li>Find StrinZ App in the Apple App Store, then tap GET.</li> <br />
          <li>
            Open StrinZ and get started by “Verify” your phone number.
          </li>{" "}
          <br />
          <li>To continue, Create your Profile.</li>
        </ul>{" "}
        <strong>Note:</strong> User can also change this later by tapping &gt;
        Settings &gt; Edit Profile.
      </>
    ),
  },
  {
    key: "3",
    header: "How Can I uninstall StrinZ App from my IOS phone?",
    body: (
      <>
        <ul>
          <li>
            Touch and hold the StrinZ icon on the Home Screen until the icons
            start to wiggle.
          </li>{" "}
          <br />
          <li>Tap the X in the corner of the StrinZ icon.</li> <br />
          <li>Tap Delete to remove the app.</li>
        </ul>{" "}
        <strong>Note:</strong> If you have issues with your Apple ID, password,
        or downloading in general, please contact Apple Support. Apple controls
        the download and installation process for all iPhone apps. We cannot
        resolve any Apple App Store related issues.
      </>
    ),
  },
  {
    key: "4",
    header: "How to update StrinZ App?",
    body: (
      <>
        <p>
          We encourage users to always use the most up-to-date version of the
          StrinZ App. Latest versions contain the newest features and bug fixes.
        </p>{" "}
        <strong>Android:</strong>
        <ul>
          <li>Find StrinZ App in the Google Play Store, then tap Update.</li>
        </ul>{" "}
        <br />
        <strong>iPhone:</strong>
        <ul>
          <li>Find StrinZ App in the Apple App Store, then tap Update.</li>{" "}
        </ul>
        <strong>Note:</strong> When registering a phone number, users will need
        to use the latest version of StrinZ; otherwise, registration might fail.
        Users can easily update the StrinZ App from the phone’s app store.
      </>
    ),
  },

  {
    key: "5",
    header: "How To Register & Verify Phone Number?",
    body: (
      <>
        <p>
          <strong>Steps:</strong>
        </p>
        <ol>
          <li>Enter phone number.</li> <br />
          <li>
            Choose country from the drop-down list. This will automatically fill
            country code on the left.
          </li>{" "}
          <br />
          <li>
            Enter phone number in the box on the right. Do not put 0 before your
            phone number.
          </li>{" "}
          <br />
          <li>Tap Verify to request a code.</li> <br />
          <li>Enter the 4-digit code received via WhatsApp or SMS.</li> <br />
        </ol>

        <ul>
          <li>User can only register a phone number of their own.</li> <br />
          <li>
            User must be able to receive WhatsApp or SMS to the phone number
            trying to register.
          </li>{" "}
          <br />
          <li>
            User must have a working internet connection through mobile data or
            Wi-Fi. While on roaming or a bad connection, registration may not
            work. To check the internet, try opening{" "}
            <a href="https://www.strinz.online/"> www.strinz.online</a> on the
            phone’s Internet browser.
          </li>
        </ul>
      </>
    ),
  },
  {
    key: "6",
    header: "How a recipient user will get a Notification of a new StrinZ?",
    body: (
      <>
        <ol>
          <li>If the recipient is not a registered user of StrinZ App:</li>{" "}
        </ol>
        <ul>
          <li>
            User will receive a WhatsApp or SMS from StrinZ App to notify, user
            can view the message by a click on a link, we strongly recommend
            downloading the StrinZ App to access full features of the App.
          </li>{" "}
        </ul>
        <ol start="2">
          <li>If the recipient is a registered user of StrinZ App:</li>
        </ol>
        <ul>
          <li>
            User will receive a notification of a new StrinZ on the mobile home
            screen if the user is not active on StrinZ App.
          </li>{" "}
          <b>OR</b>
          <li>
            User will receive a notification of a new StrinZ within the App if
            the user is active on StrinZ App.
          </li>
        </ul>
      </>
    ),
  },
  {
    key: "7",
    header: "How will I open a new StrinZ from notification?",
    body: (
      <>
        <p>To open a new StrinZ:</p>
        <ul>
          <li>Click on the link sent by WhatsApp or SMS.</li> <br />
          <li>Notification on your Mobile.</li>
        </ul>
      </>
    ),
  },
  {
    key: "8",
    header: "What If I did not receive the 4-digit code by WhatsApp?",
    body: (
      <>
        <ul>
          <li>
            Wait up to 10 minutes to receive a 4-digit code via WhatsApp or SMS.
          </li>{" "}
        </ul>
        <strong>Note:</strong> Do not guess the code, or you will be locked out
        for some time.
      </>
    ),
  },
  {
    key: "9",
    header: "What If I’m not able to register by OTP?",
    body: (
      <>
        <ul>
          <li>
            Turn your phone off, wait for 30 seconds, and turn it back on.
          </li>{" "}
          <br />
          <li>Delete and reinstall the latest version of StrinZ App.</li>
        </ul>{" "}
        <strong>Note:</strong> To check connection to the internet, try opening{" "}
        <a href="https://www.strinz.online/" target="_blank">
          {" "}
          www.strinz.online
        </a>{" "}
        on your phone’s Internet browser.
      </>
    ),
  },

  {
    key: "10",
    header: "What If I forget my 4 Digit PIN?",
    body: (
      <>
        <p>Reset PIN by following these steps:</p>
        <ul>
          <li>Open StrinZ App.</li> <br />
          <li>Click on Forgot PIN.</li> <br />
          <li>
            User will receive a 4 Digit OTP by WhatsApp or SMS on your
            registered mobile.
          </li>{" "}
          <br />
          <li>Enter 4 Digit OTP to Verify.</li> <br />
          <li>Once Verified, user will be redirected to Reset PIN.</li> <br />
          <li>Enter new 4 Digit PIN.</li> <br />
          <li>Confirm new 4 Digit PIN.</li> <br />
          <li>Click Submit to save the new PIN.</li>
        </ul>
      </>
    ),
  },
  {
    key: "11",
    header: "How To Create profile on StrinZ App?",
    body: (
      <>
        <p>
          Open StrinZ App on your registered phone&gt; Verify OTP, if user is
          using the App for the first time, then it will be automatically
          redirected to Create Profile
        </p>
        <br />
        <u>
          <b>Steps:</b>
        </u>
        <ol>
          <li>
            Click on Image Icon to choose profile Avatar which best represent
            you.
          </li>
          <br />
          <li>Enter Full Name</li> <br />
          <li>Enter User Name</li> <br />
          <li>Choose Gender</li> <br />
          <li>
            Create PIN: Enter 4 Digit PIN, Re-enter 4 Digit PIN to confirm PIN
          </li>{" "}
          <br />
          <li>
            Confirm your AGE by a click, confirm that user is 13 years+ of age
          </li>{" "}
          <br />
          <li>
            Read &amp; Accept our Privacy Policy and Terms and Conditions by
            click on check box
          </li>{" "}
          <br />
          <li>
            Once all the mandatory information is entered, Click SAVE to create
            your profile
          </li>
        </ol>
      </>
    ),
  },
  {
    key: "12",
    header: "Can I Use Emoji in my Name or User Name on StrinZ App?",
    body: (
      <>
        <p>
          In current version, user cannot create a Name or User Name containing
          Emoji, we are working continuously to improve user’s experience.
        </p>
      </>
    ),
  },
  {
    key: "13",
    header:
      "Can I access device camera or gallery to upload an image as profile pic on StrinZ App?",
    body: (
      <>
        <p>
          In current version, user cannot use your camera or phone gallery to
          upload an image, user can only choose one of the Avatars available on
          StrinZ app, we are working continuously to improve user’s experience.
        </p>
      </>
    ),
  },
  {
    key: "14",
    header: "Can I choose not to disclose my Gender on StrinZ App?",
    body: (
      <>
        <p>
          In StrinZ App, declaration of Gender is mandatory for its functioning
          StrinZ are created based on relationship among users and it is must to
          know the gender of user to generate a suitable message to express the
          emotions. To respect user’s privacy and to support LGBTQ community,
          there is an option of OTHERS as Gender, we are working continuously to
          improve user’s experience.
        </p>
      </>
    ),
  },
  {
    key: "15",
    header: "How to add a New Contact in StrinZ App?",
    body: (
      <>
        <ul>
          <li>Open StrinZ on your registered phone, Go to Chat.</li> <br />
          <li>Click on Create StrinZ Icon</li>
        </ul>
        Select New Contact&gt;Proceed, user will be redirected to enter details
        of new contact:
        <ul>
          <br /> <li>Name</li> <br />
          <li>Mobile Number with Country Code</li>
          <br />
          <li> Click Submit to save contact.</li>{" "}
        </ul>
      </>
    ),
  },
  {
    key: "16",
    header: "How to send StrinZ to a Contact from “Phone Book”?",
    body: (
      <>
        <ul>
          <li>Open StrinZ on your registered phone, Go to Chat.</li> <br />
          <li>Click on Create StrinZ Icon.</li> <br />
          <li>Click Select from Contacts Icon.</li> <br />
          <li>Select Contact from list of saved contacts.</li>
        </ul>
      </>
    ),
  },
  {
    key: "17",
    header: "How to Choose a Relationship between Sender and Receiver?",
    body: (
      <>
        <ul>
          <li>Open StrinZ on your registered phone, Go to Chat.</li> <br />
          <li>Click on Create StrinZ Icon.</li> <br />
          <li>Select Contact accordingly.</li> <br />
          <li>
            Click Create StrinZ, user will be redirected to Create StrinZ page.
          </li>{" "}
          <br />
          <li>
            Click drop down Select Relationship to select the Relationship from
            the list.
          </li>
        </ul>
      </>
    ),
  },
  {
    key: "18",
    header: "How to Choose Emotions?",
    body: (
      <>
        <ul>
          <li>Open StrinZ on your registered phone, Go to Chat</li> <br />
          <li>Click on Create StrinZ Icon.</li> <br />
          <li>Select Contact accordingly.</li> <br />
          <li>
            Click Create StrinZ, user will be redirected to Create StrinZ page.
          </li>{" "}
          <br />
          <li>Choose Emotion Icon of your choice.</li>
        </ul>
      </>
    ),
  },
  {
    key: "19",
    header: "How to Create StrinZ?",
    body: (
      <>
        <ul>
          <li>Open StrinZ on your registered phone, Go to Chat</li> <br />
          <li>Click on Create StrinZ Icon</li> <br />
          <li>Select Contact accordingly</li> <br />
          <li>
            Click Create StrinZ, user will be redirected to Create StrinZ page
          </li>{" "}
          <br />
          <li>
            Click drop down Select Relationship to select the Relationship from
            the list
          </li>{" "}
          <br />
          <li>Choose Emotion Icon of your choice</li> <br />
          <li>Click Create StrinZ</li>
        </ul>
      </>
    ),
  },
  {
    key: "20",
    header: "How To Select StrinZ?",
    body: (
      <>
        StrinZ is powered by AI
        <br /> <br />
        <ul>
          <li>
            A list of StrinZ will be generated with a click on Create StrinZ.
          </li>{" "}
          <br />
          <li>Scroll LEFT or RIGHT to generate and view more StrinZ.</li> <br />
          <li>Tap on any StrinZ to send.</li> <br />
          <li>Further user can choose to Edit to personalize the StrinZ.</li>
        </ul>
      </>
    ),
  },
  {
    key: "21",
    header: "How to Edit StrinZ?",
    body: (
      <>
        User can personalize StrinZ by changing the background, font, colour or
        add AI generated stickers
        <ul>
          <li>
            Choose StrinZ&gt;Edit&gt;Tap on change colour option, user can
            change background or text colour from a list of options
          </li>{" "}
          <br />
          <li>
            Choose StrinZ&gt;Edit&gt;Tap on change font, user can change font of
            your message from suggested options
          </li>{" "}
          <br />
          <li>
            Choose StrinZ&gt;Edit&gt;Tap on add element option, user can add AI
            generated stickers on top of your message
          </li>
        </ul>
        <strong> Note:</strong> StrinZ is a NO TYPING APP, user cannot change
        the text of a StrinZ
      </>
    ),
  },
  {
    key: "22",
    header: "How can I send StrinZ without editing?",
    body: (
      <ul>
        <strong>
          StrinZ is powered by AI, each StrinZ generated can be shared without
          any editing:
        </strong>
        <br />
        <li>Tap on any StrinZ to send.</li> <br />
        <li>
          Choose SELECT, user will be redirected to a preview of StrinZ.
        </li>{" "}
        <br />
        <li>Click “SEND” to share StrinZ.</li> <br />
        <strong> Note:</strong>
        User can also choose to edit with a click on EDIT on preview stage
      </ul>
    ),
  },
  {
    key: "23",
    header: "How can I access my previous chats?",
    body: (
      <ul>
        <strong> Open StrinZ on your registered phone:</strong>
        <br />
        <li>User will be automatically redirected to Chat StrinZ.</li> <br />
        <li>User will see the list of chats on Chat page.</li> <br />
        <strong> In case the user is logged out due to any reason:</strong>
        <li>Open StrinZ.</li> <br />
        <li>
          Enter 4 Digit PIN, user will be automatically redirected to Chat
          StrinZ.
        </li>{" "}
        <br />
        <li>.User will see the list of chats on Chat page</li>
      </ul>
    ),
  },
  {
    key: "24",
    header: "How to send reply to StrinZ?",
    body: (
      <ul>
        Open Chat page
        <br /> <br />
        <strong>Option 1:</strong>
        <li>Click on chat.</li> <br />
        <li>Click on StrinZ to Reply.</li> <br />
        <li>
          Choose Option Reply, user will be redirected to Create StrinZ, user
          can create a StrinZ to reply.
        </li>{" "}
        <br />
        <strong>Option 2:</strong>
        <br />
        <li>Click on chat.</li> <br />
        <li>Click on Emoji Icon to reply with Emoji</li> <br />
        <strong>Option 3:</strong>
        <br />
        <li>Click on chat</li> <br />
        <li> Click on stickies Icon to reply with AI generated Stickers</li>
      </ul>
    ),
  },
  {
    key: "25",
    header: "How to delete a Chat?",
    body: (
      <>
        Open Chat page
        <br /> <br />
        <strong> Option 1:</strong>
        <ul>
          <li>Scroll lefton Chatto delete</li> <br />
          <li>
            Choose option Delete, chat will be deleted after your confirmation
          </li>
        </ul>
        <br />
        <strong> Option 2:</strong>
        <ul>
          <li>Scroll left on Chatto delete</li> <br />
          <li>Choose More options (:) Icon</li> <br />
          <li>
            Chooseoption Delete, chat will be deleted after your confirmation
          </li>
        </ul>
        <br /> <strong> Note: </strong>
        You cannot retrieve the deleted Chat.
      </>
    ),
  },
  {
    key: "26",
    header: "How to Mute a Chat?",
    body: (
      <>
        Open Chat page
        <br /> <br />
        <ul>
          <li>Scroll left on Chat to Mute</li> <br />
          <li>Choose More options (:) Icon</li> <br />
          <li>Choose Option Mute, Chat will be mute after your confirmation</li>
        </ul>
      </>
    ),
  },
  {
    key: "27",
    header: "How to Unmute a Chat?",
    body: (
      <>
        Open Chat page
        <br /> <br />
        <ul>
          <li>Scroll left on Chat to Mute</li> <br />
          <li>Choose More options (:) Icon</li> <br />
          <li>
            Choose Option UNMUTE, Chat will be unmute after your confirmation
          </li>
        </ul>
      </>
    ),
  },
  {
    key: "28",
    header: "How to mark Chat as “Favourite”?",
    body: (
      <>
        Open Chat page
        <br /> <br />
        <ul>
          <li>Scroll left on Chat you want to mark Favourite</li> <br />
          <li>Choose More options (:) Icon</li> <br />
          <li>
            Choose Option Favourite, chat will be marked asfavourite after your
            confirmation
          </li>
        </ul>
        <strong>Note: </strong>
        Chats marked as Favourite will be displayed on top of Chat list.
      </>
    ),
  },
  {
    key: "29",
    header: "How to remove a chat as “Favourite”?",
    body: (
      <>
        Open Chat page
        <br /> <br />
        <ul>
          <li>Scroll left on Chat you want to mark Favourite</li> <br />
          <li>
            Click Unfavourite Icon in front of a Chat, Chat will be removed from
            Favourite after your confirmation
          </li>
        </ul>
      </>
    ),
  },
  {
    key: "30",
    header: "How to delete a Strinz?",
    body: (
      <>
        Open Chat page
        <br /> <br />
        <ul>
          <li>Click on user’s chat</li> <br />
          <li>Click on StrinZyou want to Delete.</li> <br />
          <li>
            Choose option Delete, message will be deleted after your
            confirmation.
          </li>
        </ul>{" "}
        <strong> Note: </strong> You cannot retrieve the deleted StrinZ.
      </>
    ),
  },

  {
    key: "31",
    header: "How to Block a Contact?",
    body: (
      <ul>
        Open Chat Page
        <br />
        <br />
        <li>Scroll left on Chat to Block.</li> <br />
        <li> Choose More options (:) Icon.</li> <br />
        <li>
          Choose Option Block StrinZ, contact will be blocked after your
          confirmation.
        </li>
      </ul>
    ),
  },
  {
    key: "32",
    header: "How to Unblock a Contact?",
    body: (
      <ul>
        Open Chat Page
        <br /> <br />
        <li>Scroll left on Chat to Unblock.</li> <br />
        <li>Choose More options (:) Icon.</li> <br />
        <li>
          Click Unblock Icon in front of a Chat, Chat will be Unblocked after
          your confirmation.
        </li>
      </ul>
    ),
  },
  {
    key: "33",
    header: "How to change profile Avatar?",
    body: (
      <ul>
        <li> Click Settings Icon.</li> <br />
        <li>Click Account Settings.</li> <br />
        <li>Click Edit.</li> <br />
        <li>
          Click Image Icon, user can choose from a list of AI-generated Avatars.
        </li>{" "}
        <br />
        <li>Click SAVE button to save the changes.</li>
      </ul>
    ),
  },
  {
    key: "34",
    header: "How to change User Name in profile?",
    body: (
      <ul>
        <li> Click Settings Icon</li> <br />
        <li> Click Account Settings</li> <br />
        <li> Click Edit</li> <br />
        <li> Type New Username in User Name column</li> <br />
        <li> Click SAVE button to save the changes</li>
        <br />
        <li>Click SAVE button to save the changes.</li>
      </ul>
    ),
  },
  {
    key: "35",
    header: "How to change Gender in profile?",
    body: (
      <ul>
        <li> Click Settings Icon</li> <br />
        <li> Click Account Settings</li> <br />
        <li> Click Edit</li> <br />
        <li> Click on drop down Icon in front of Relationship column</li> <br />
        <li> Choose Gender from the list</li> <br />
        <li> Click SAVE button to save the changes</li>
      </ul>
    ),
  },
  {
    key: "36",
    header: "How to change Name in profile?",
    body: (
      <ul>
        <li> Click Settings Icon</li> <br />
        <li> Click Account Settings</li> <br />
        <li> Click Edit</li> <br />
        <li>Type New name in Namecolumn</li> <br />
        <li>Click SAVE button to save the changes</li> <br />
        <li> Click SAVE button to save the changes</li>
      </ul>
    ),
  },
  {
    key: "37",
    header: "How to change PIN for future login?",
    body: (
      <ul>
        <li> Click Settings Icon</li> <br />
        <li>
          {" "}
          Click Change PIN, user will be redirected to Change PIN page
        </li>{" "}
        <br />
        <li> Enter existing 4 Digit PIN</li> <br />
        <li> Enter new 4 Digit PIN</li> <br />
        <li> Re Enter new 4 Digit PIN</li> <br />
        <li> Click SUBMIT button to save the changes</li>
      </ul>
    ),
  },

  {
    key: "38",
    header: "How to Delete my account on StrinZ App?",
    body: (
      <ul>
        <li> Click Settings Icon.</li> <br />
        <li>Click Delete Account.</li> <br />
        <li>Select a reason to delete StrinZ account.</li> <br />
        <li>Click Delete button to save the changes.</li> <br />
        <strong>Note: </strong> If you choose to Delete your account, all your
        information including your chats will be deleted permanently.
      </ul>
    ),
  },
  {
    key: "39",
    header: "How to Log out from StrinZ App?",
    body: (
      <ul>
        <li>Click Settings Icon.</li> <br />
        <li>Click Log Out.</li>
        <br />
        <strong>Note:</strong> Once logged out, user need to enter 4 Digit Pin
        to Re-login to StrinZ App.
      </ul>
    ),
  },
  {
    key: "40",
    header: "How to access Privacy Policy of StrinZ App?",
    body: (
      <ul>
        <li>Click Settings Icon.</li> <br />
        <li>
          Click Privacy Policy, user will be redirected to privacy policy page.
        </li>
      </ul>
    ),
  },
  {
    key: "41",
    header: "How to access Terms and Conditions of StrinZ App?",
    body: (
      <ul>
        <li> Click Settings Icon.</li> <br />
        <li>
          Click Terms and Conditions, user will be redirected to Terms and
          Conditions page.
        </li>
      </ul>
    ),
  },
  {
    key: "42",
    header: "How to access Help to use StrinZ App?",
    body: (
      <ul>
        <li> Click Settings Icon.</li> <br />
        <li>Click Help, user will be redirected to Help Center page.</li>
      </ul>
    ),
  },
  {
    key: "43",
    header: "How to change Registered Mobile Number for StrinZ App?",
    body: (
      <>
        In the current version, user cannot change your registered number, we
        are working continuously to improve user’s experience.
      </>
    ),
  },
  {
    key: "44",
    header: "How to change Mobile Phone Device for StrinZ App?",
    body: (
      <>
        In the current version, user cannot change your device, we are working
        continuously to improve user’s experience.
      </>
    ),
  },
  {
    key: "45",
    header: "How to Protect your StrinZ account?",
    body: (
      <ul>
        <li>
          Never share your 4-digit Verification code or 4-digit PIN with others.
        </li>
        <br />
        <li>
          Set a device code and be aware of who has physical access to your
          phone.
        </li>
        <br />
        <li>
          Someone who has physical access to your phone could use your StrinZ
          App account without your permission.
        </li>
        <br />
        <li>Beware of suspicious emails or messages.</li> <br />
        <li>
          Be careful if you receive a message or an email asking you to share or
          reset your 4-digit PIN or registration code. If you did not request
          this, do not click on the link. Someone could be trying to access your
          phone number on StrinZ App.
        </li>
      </ul>
    ),
  },
  {
    key: "46",
    header: "About using StrinZ App abroad?",
    body: (
      <ul>
        <li>
          {" "}
          When you are traveling out of your country, you can still use StrinZ
          App via mobile data or Wi-Fi.
        </li>
        <br />
        <li>
          {" "}
          If you use a local SIM card while you are traveling, you can still use
          StrinZ App with your home number. However, in this case, if you need
          to re-verify your account, you will not be able to do this while using
          the local SIM card.
        </li>
        <br />
        <li>
          To re-verify / verify a phone number with StrinZ, you must have the
          corresponding SIM card in your phone, with phone or WhatsApp service
          enabled.
        </li>
      </ul>
    ),
  },
  {
    key: "47",
    header: "How to delete my account on StrinZ App?",
    body: (
      <>
        <ul>
          <li>Click Settings Icon.</li>
          <br />
          <li>Click Delete Account.</li>
          <br />
          <li>Select a reason to delete StrinZ account.</li>
          <br />
          <li>Click Delete button to save the changes.</li>
        </ul>
        <strong>Note:</strong> If you choose to Delete your account, all your
        information including your chats will be deleted permanently.
      </>
    ),
  },
];

export default faqData;
