// PrivacyPolicyApi.js
import axios from "axios";
import { BASE_API_URL } from "../../config/apiURL/Base_Api";
import { handle500Error } from "../../config/logout500/Handle500";

export const fetchPrivacyPolicyApi = async (token) => {
  try {
    const response = await axios.get(`${BASE_API_URL}/getPrivacyPolicy`, {
      headers: {
        Auth: token,
      },
    });

    if (response.data.status === 401) {
      handle500Error();
      return { error: "Internal Server Error" };
    }
    if (response.data.status === 200) {
      return { value: response.data.data };
    } else {
      return { error: "No privacy policy data found." };
    }
  } catch (error) {
    console.error("Error fetching privacy policy:", error);
    return { error: "Failed to fetch privacy policy. Please try again." };
  }
};

export const updatePrivacyPolicyApi = async (token, value, transPassword) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}/updatePrivacyPolicy`,
      { content: value, transPass: transPassword },
      {
        headers: {
          Auth: token,
        },
      }
    );
    if (response.data.status === 401) {
      handle500Error();
      return { error: "Internal Server Error" };
    }
    return { success: true };
  } catch (error) {
    console.error("Error updating privacy policy:", error);
    return { error: "Failed to update privacy policy. Please try again." };
  }
};
