import React from "react";
import { Routes, Route } from "react-router-dom";
import MainLayout from "../components/MainLayout";

import Home from "../components/Home/Home";
import About from "../components/About/About";
import EULA from "../components/EULA/EULA";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";
import Terms from "../components/Terms/Terms";
import FrequentQuestion from "../components/FAQ/FAQ";
import Contact from "../components/Contact/Contact";
import JoinUs from "../components/Join Us/JoinUs";
import MessagePage from "../appPages/pages/MessagePage/MessagePage";
import Login from "../admin/pages/login/Login";
import Otp from "../admin/pages/login/Otp";
import PrivateRoute from "./PrivateRoute";
import List from "../admin/pages/list/List";
import Single from "../admin/pages/single/Single";
import New from "../admin/pages/new/New";
import RelationMessage from "../admin/pages/relationMessage/RelationMessge";
import Relations from "../admin/pages/Relations";
import { userInputs } from "../admin/formSource";
import AdminHome from "../admin/pages/home/AdminHome";

import StickyPage from "../admin/pages/stickiPage/StickyPage";

import BackgroundPage from "../admin/pages/bgpage/BackGround";

import PrivPol from "../admin/pages/policyy/PrivaPol";
import EU from "../admin/pages/policyy/EU";
import FA from "../admin/pages/policyy/FA";
import TC from "../admin/pages/policyy/TC";
import PageNotFound from "../admin/pages/PageNotFound";

import LoginPass from "../admin/pages/passChange/LoginPass";
import TransPass from "../admin/pages/passChange/TransPass";

const AppRoutes = () => (
  <Routes>
    <Route
      path="/"
      element={
        <MainLayout>
          <Home />
        </MainLayout>
      }
    />
    <Route
      path="/about"
      element={
        <MainLayout>
          <About />
        </MainLayout>
      }
    />
    <Route
      path="/eula"
      element={
        <MainLayout>
          <EULA />
        </MainLayout>
      }
    />
    <Route
      path="/privacy"
      element={
        <MainLayout>
          <PrivacyPolicy />
        </MainLayout>
      }
    />
    <Route
      path="/term"
      element={
        <MainLayout>
          <Terms />
        </MainLayout>
      }
    />
    <Route
      path="/faq"
      element={
        <MainLayout>
          <FrequentQuestion />
        </MainLayout>
      }
    />
    <Route
      path="/contact"
      element={
        <MainLayout>
          <Contact />
        </MainLayout>
      }
    />
    <Route
      path="/joinus"
      element={
        <MainLayout>
          <JoinUs />
        </MainLayout>
      }
    />
    <Route path="/Message" element={<MessagePage />} />
    <Route path="/admin" element={<Login />} />
    <Route path="/otp" element={<Otp />} />
    <Route path="*" element={<PageNotFound />} />
    <Route path="/admin/user" element={<PrivateRoute />}>
      <Route path="home" element={<AdminHome />} />
      <Route path="users" element={<List />}></Route>
      <Route path=":id" element={<Single />} />
      <Route
        path="new"
        element={<New inputs={userInputs} title="Add New User" />}
      />
      <Route path="products" element={<List />} />
      <Route path="messages" element={<RelationMessage />} />
      <Route path="relations" element={<Relations />} />
      <Route path="stickies" element={<StickyPage />} />
      <Route path="background" element={<BackgroundPage />} />
      <Route path="privacy" element={<PrivPol />} />
      <Route path="eulaPolicy" element={<EU />} />
      <Route path="FaqEdit" element={<FA />} />
      <Route path="T&C" element={<TC />} />
      <Route path="changeTransPass" element={<TransPass />} />
      <Route path="changePass" element={<LoginPass />} />

      <Route path="*" element={<PageNotFound />} />
    </Route>
  </Routes>
);

export default AppRoutes;
