// components/StickerModal.jsx
import React from "react";
import Modal from "react-modal";
import StickyTypeDropdown from "./StickiesDropDown";
import { customModalStyles } from "../utils/customModalStyles";

const StickerModal = ({
  isOpen,
  closeModal,
  handleSubmit,
  handleFileChange,
  stickyType,
  setStickyType,
  types,
  loadingStickers,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customModalStyles}
      contentLabel="Upload Sticker Modal"
    >
      <h2>Add a Sticker</h2>
      <form onSubmit={handleSubmit}>
        <StickyTypeDropdown
          stickyType={stickyType}
          setStickyType={setStickyType}
          types={types}
        />
        <div>
          <label htmlFor="file">File:</label>
          <input
            type="file"
            id="file"
            accept="image/png"
            onChange={handleFileChange}
          />
        </div>
        <div className="bton-container">
          <button type="submit" className="download" disabled={loadingStickers}>
            Upload{" "}
          </button>
          <button
            type="button"
            className="download"
            onClick={closeModal}
            disabled={loadingStickers}
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default StickerModal;
