import React from "react";
import newimgs from "../../assets/explore (3).png";
import "./Carousel.css";
import Downbtn from "../DownloadBtn/Downbtn";

const Explore = () => {
  return (
    // <section className="contain">
    //   <div className="image-container">
    //     <img
    //       src={newimgs}
    //       className="explore-image"
    //       alt="Explore"
    //       useMap="#image-map"
    //     />
    //   </div>
    // </section>
    <section>
      <div
        style={{
          position: "relative",
        }}
      >
        <div>
          <img src={newimgs} alt="BigCo Inc. logo" className="expContainer" />
        </div>
        <div className="textContainer">
          <div className="ExText">
            <h2>
              Explore expressive
              <br /> communication
              <br /> with StrinZ app.
            </h2>
            <p>
              Experience a new level of expressive
              <br /> communication with our chat app, where
              <br /> emotions come to life through a vibrant array
              <br /> of emojis and stickers, adding a touch of fun
              <br /> and personalization to every conversation.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Explore;
