import axios from "axios";
import { BASE_API_URL } from "../../config/apiURL/Base_Api";
import { handle500Error } from "../../config/logout500/Handle500";

export const fetchRelationships = async (token) => {
  try {
    const response = await axios.get(`${BASE_API_URL}/getAllRelationShip`, {
      headers: {
        "Content-Type": "multipart/form-data",
        auth: token,
      },
    });
    if (response.data.status === 401) {
      handle500Error();
      return;
    }
    return response.data.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

export const addRelationship = async (newRelationship, token, transPass) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}/relationship/addRelationShip`,
      {
        name: newRelationship.name,
        male: newRelationship.male,
        female: newRelationship.female,
        other: newRelationship.other,
        transPass: transPass,
      },
      {
        headers: {
          "Content-Type": "application/json",
          auth: token,
        },
      }
    );
    if (response.data.status === 200) {
      alert("Operation successfull");
    }
    if (response.data.status === 401) {
      handle500Error();
      return;
    }
    return response.data.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

export const deleteRelationship = async (id, token, transPass) => {
  try {
    const response = await axios.delete(
      `${BASE_API_URL}/relationship/delete/${id}`,

      {
        headers: {
          auth: token,
        },
        data: { transPass: transPass },
      }
    );
    if (response.data.status === 200) {
      alert("Operation successfull");
    }
    if (response.data.status === 401) {
      handle500Error();
      return;
    }
    return response.data.status;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

export const updateRelationships = async (
  updatedRelationship,
  token,
  transPass
) => {
  try {

    const response = await axios.post(
      `${BASE_API_URL}/relationship/updateRelationShip`,
      {
        id: updatedRelationship.id,

        male: updatedRelationship.male,
        female: updatedRelationship.female,
        other: updatedRelationship.other,
        transPass: transPass,
      },

      {
        headers: {
          "Content-Type": "application/json",
          auth: token,
        },
      }
    );
    if (response.data.status === 200) {
      alert("Operation successfull");
    }

    if (response.data.status === 401) {
      handle500Error();
      return;
    }

    return response.data.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};
