// api.js

import axios from "axios";
import { BASE_API_URL } from "../../config/apiURL/Base_Api";
import { handle500Error } from "../../config/logout500/Handle500";

export const fetchEULA = async (token) => {
  try {
    const response = await axios.get(`${BASE_API_URL}/getEndUSerLicence`, {
      headers: {
        Auth: token,
      },
    });
    if (response.data.status === 401) {
      handle500Error();
      return;
    }
    if (response.data.status === 500) {
      return { error: "Failed to fetch EULA" };
    }
    if (response.data && response.data.data.length > 0) {
      return { value: response.data.data[0].code };
    } else {
      return { error: "No EULA data found" };
    }
  } catch (error) {
    console.error("Error fetching EULA:", error);
    return { error: "Failed to fetch EULA. Please try again." };
  }
};

export const updateEULA = async (token, content, transPassword) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}/updateEndUSerLicence`,
      { content, transPass: transPassword },
      {
        headers: {
          Auth: token,
        },
      }
    );
    if (response.data.status === 401) {
      handle500Error();
      return;
    }
    if (response.data.status === 200) {
      return { success: true };
    } else if (response.data.status === 500) {
      return { error: "Incorrect transaction password." };
    }
  } catch (error) {
    console.error("Error updating EULA:", error);
    return { error: "Failed to update EULA. Please try again." };
  }
};
