// AddEULA.js

import React, { useState } from "react";
import "react-quill/dist/quill.snow.css";
import PolicyModal from "./PolicyModal";
import Button from "../utils/Button";
import CustomQuill from "../utils/CustomEditor";
import TransactionPasswordModal from "../utils/TransModal";
import useEULA from "./policiesHook/useEula";

const AddEULA = ({ token, onUpdate, value, setValue }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isTransPassModalOpen, setIsTransPassModalOpen] = useState(false);
  const [loadingTransPass, setLoadingTransPass] = useState(false);

  const { loading, canWrite, handleUpdateEULA, setError } = useEULA();

  const handleChange = (content) => {
    setValue(content);
  };

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleTransPassSubmit = async (transPassword) => {
    await handleUpdateEULA(token, value, transPassword, onUpdate);
    setIsTransPassModalOpen(false);
    setLoadingTransPass(false);
  };

  const handleSave = () => {
    if (!value.trim()) {
      setError("Content cannot be empty.");
      return;
    }
    setIsTransPassModalOpen(true);
  };

  return (
    <>
      {canWrite && (
        <Button
          onClick={handleOpenModal}
          disabled={loading}
          className="download"
        >
          Add EULA
        </Button>
      )}
      <PolicyModal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        value={value}
      >
        <h2>Add EULA</h2>
        <div>
          <CustomQuill
            value={value}
            onChange={handleChange}
            style={{ height: "100vh" }}
          />
        </div>

        <div className="bton-container">
          <Button onClick={handleSave} disabled={loading} className="download">
            {loading ? "Saving..." : "Save"}
          </Button>
          <Button
            onClick={handleCloseModal}
            disabled={loading}
            className="download"
          >
            Cancel
          </Button>
        </div>
      </PolicyModal>
      <TransactionPasswordModal
        isOpen={isTransPassModalOpen}
        setIsTransPassModalOpen={setIsTransPassModalOpen}
        onSubmit={handleTransPassSubmit}
        loading={loadingTransPass}
      />
    </>
  );
};

export default AddEULA;
