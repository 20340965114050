import React, { useState } from "react";
import CustomModal from "../utils/Modal";
import "./styling/RelationshipData.css";
import Button from "../utils/Button";
const AddSingleMessage = ({
  isOpen,
  onRequestClose,
  handleAddSingleMessage,
  relation,
  emotion,
  error,
  setError,
}) => {
  const [message, setMessage] = useState("");
  const data = { relation, emotion, message };
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = () => {
    if (!message) {
      setError("Message can't be empty!!!");
      return;
    }
    handleAddSingleMessage(data);

    onRequestClose();
    setMessage("");
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={`Add Message for ${emotion}`}
    >
      <div>
        <label>
          Relationship ID:
          <input
            type="text"
            value={relation}
            readOnly
            style={{ color: "grey" }}
          />
        </label>
      </div>
      <div>
        <label>
          Emotion:
          <input
            type="text"
            value={emotion}
            readOnly
            style={{ color: "grey" }}
          />
        </label>
      </div>
      <div>
        <label>
          Message:
          <input
            type="text"
            value={message}
            onChange={handleMessageChange}
            required
          />
        </label>
        {error && <div className="error-message">{error}</div>}
      </div>

      <div className="bton-container">
        <Button onClick={handleSubmit}>Submit</Button>
        <Button
          onClick={() => {
            onRequestClose();
            setError("");
          }}
        >
          Close
        </Button>
      </div>
    </CustomModal>
  );
};

export default AddSingleMessage;
