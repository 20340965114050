import React from "react";
import CommonHeader from "../Common/CommonHeade";
import { useScrollToTop } from "../useScrollToTop";
import { TermaData } from "../DATA/TermData";
import "../Common/Common.css";
const Terms = () => {
  useScrollToTop();
  return (
    <div className="wrapper">
      <div className="wrap">
        <CommonHeader children={"Term & Conditions"} />
        {TermaData?.map((term) => (
          <div key={term?.key}>{term?.body}</div>
        ))}
      </div>
    </div>
  );
};

export default Terms;
