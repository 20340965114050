import React, { useState } from "react";
import MessageList from "./MessageList";
import AddSingleMessage from "./AddSingleMessage";
import RelationshipSelector from "./RelationSelector";
import AddMessageSection from "./AddMessageSection";
import EditMessageModal from "./EditMessage";
import { usePermission } from "../utils/useToken";
import useRelationshipMessages from "./messageDatahook/useFetchmessage";
import AddSingleMessageButton from "./AddMessageButton";
import TransactionPasswordModal from "../utils/TransModal";

const MessageData = () => {
  const {
    relationships,
    emotions, // Destructure emotions from useRelationshipMessages
    selectedRelation,
    selectedEmotion,
    allMessages,
    loadingMessages,
    deletingId,
    error,
    editingMessage,
    isEditModalOpen,
    selectedMessages,
    page,
    count,
    setIsAddModalOpen,
    handleRelationChange,
    handleEmotionChange,
    handleEditMessage,
    handleUpdateMessage,
    handleDeleteMessage,
    handleAddMessages,
    handleAddSingleMessage,
    setEditingMessage,
    setSelectedMessages,
    setIsEditModalOpen,
    isAddModalOpen,
    setPage,
    setError,
    setIsAddSingleModalOpen,
    isAddSingleModalOpen,
  } = useRelationshipMessages();

  const { permission } = usePermission();

  const [isTransPassModalOpen, setIsTransPassModalOpen] = useState(false);
  const [loadingTransPass, setLoadingTransPass] = useState(false);
  const [currentOperation, setCurrentOperation] = useState(null);
  const [currentOperationData, setCurrentOperationData] = useState(null);

  const openTransPassModal = (operation, data) => {
    setCurrentOperation(operation);
    setCurrentOperationData(data);
    setIsTransPassModalOpen(true);
  };
  const handleTransPassSubmit = async (transPassword) => {
    setLoadingTransPass(true);
    try {
      if (currentOperation === "add") {
        await handleAddSingleMessage(currentOperationData, transPassword);
      } else if (currentOperation === "edit") {
        await handleUpdateMessage(currentOperationData, transPassword);
      } else if (currentOperation === "delete") {
        await handleDeleteMessage(currentOperationData, transPassword);
      } else if (currentOperation === "addviafile") {
        await handleAddMessages(currentOperationData, transPassword);
      }
    } catch (error) {
      alert(`Error ${currentOperation}ing message: ${error.message}`);
    } finally {
      setIsTransPassModalOpen(false);
      setLoadingTransPass(false);
    }
  };
  return (
    <>
      <div style={{ margin: "20px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <RelationshipSelector
            relationships={relationships}
            emotions={emotions} // Pass emotions to RelationshipSelector
            selectedRelation={selectedRelation}
            selectedEmotion={selectedEmotion}
            onRelationChange={handleRelationChange}
            onEmotionChange={handleEmotionChange}
          />
          {permission === "write" && (
            <AddMessageSection
              handleAddMessages={(data) =>
                openTransPassModal("addviafile", data)
              }
              selectedRelation={selectedRelation}
              setIsAddModalOpen={setIsAddModalOpen}
              selectedEmotion={selectedEmotion}
              isEditModalOpen={isEditModalOpen}
              isAddModalOpen={isAddModalOpen}
            />
          )}
        </div>
        {permission === "write" && (
          <AddSingleMessageButton
            setIsAddSingleModalOpen={setIsAddSingleModalOpen}
            selectedRelation={selectedRelation}
            selectedEmotion={selectedEmotion}
          />
        )}
        <MessageList
          messages={allMessages}
          handleEditMessage={handleEditMessage}
          handleDeleteMessage={(id) => openTransPassModal("delete", id)}
          selectedMessages={selectedMessages}
          setSelectedMessages={setSelectedMessages}
          loadingMessages={loadingMessages}
          selectedRelation={selectedRelation}
          selectedEmotion={selectedEmotion}
          setPage={setPage}
          count={count}
          page={page}
          permission={permission}
          deletingId={deletingId}
        />
        {editingMessage && (
          <EditMessageModal
            message={editingMessage}
            onSave={(data) => openTransPassModal("edit", data)}
            isEditModalOpen={isEditModalOpen}
            setIsEditModalOpen={setIsEditModalOpen}
            onClose={() => setEditingMessage(null)}
          />
        )}
        <AddSingleMessage
          isOpen={isAddSingleModalOpen}
          onRequestClose={() => setIsAddSingleModalOpen(false)}
          relation={selectedRelation}
          emotion={selectedEmotion}
          handleAddSingleMessage={(data) => openTransPassModal("add", data)}
          error={error}
          setError={setError}
        />
      </div>
      <TransactionPasswordModal
        isOpen={isTransPassModalOpen}
        setIsTransPassModalOpen={setIsTransPassModalOpen}
        onSubmit={handleTransPassSubmit}
        loading={loadingTransPass}
      />
    </>
  );
};

export default MessageData;
