import banner from "../assets/banner.png";
import backtwo from "../assets/back-2.png";
import backthree from "../assets/back-3.png";
import backfour from "../assets/back-4.png";
import bannerMessage from "../assets/banner-message.png";
import backtwoMessage from "../assets/back-2-message.png";
import backthreeMessage from "../assets/back-3-message.png";
import backfourMessage from "../assets/back-4-message.png";
import appStore from "../assets/app/appStore.png";
import playStore from "../assets/app/playStore.png";
export const HomeData = [
  {
    id: 1,
    bannerUrl: banner,
    message: bannerMessage,
    alt: "bannerMessage",
    bgColor: "#f9f1ff",
  },
  {
    id: 2,
    bannerUrl: backtwo,
    message: backtwoMessage,
    alt: "backtwoMessage",
    bgColor: "#d8eced",
  },
  {
    id: 3,
    bannerUrl: backthree,
    message: backthreeMessage,
    alt: "backthreeMessage",
    bgColor: "#f7eefe",
  },
  {
    id: 4,
    bannerUrl: backfour,
    message: backfourMessage,
    alt: "backfourMessage",
    bgColor: "#e7eaec",
  },
];

export const storeLink = [
  {
    link: "https://apps.apple.com/in/app/strinz/id6472786523",
    image: appStore,
    alt: "App Store Button",
  },
  {
    link: "https://play.google.com/store/search?q=strinz&c=apps&hl=en_IN&gl=US",
    image: playStore,
    alt: "Play Store Button",
  },
];
