export const footerLinks = [
  { path: "/about", label: "About us" },
  { path: "/eula", label: "EULA" },
  { path: "/term", label: "Term & Conditions" },
  { path: "/faq", label: "FAQ" },

  { path: "/joinus", label: "Join Us" },
  { path: "/privacy", label: "Privacy Policy" },
  { path: "/contact", label: "Contact us" },
];
