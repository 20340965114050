import React from "react";
import Header from "./Layout/Header/Header";
import Footer from "./Layout/Footer/Footer";

const MainLayout = (props) => {
  return (
    <>
      <Header />
      {props.children}
      <Footer />
    </>
  );
};
export default MainLayout;
