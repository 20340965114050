import React from "react";
import "./filter.css";

const Filter = ({ types, onFilterChange, activeTab }) => {
  return (
    <div>
      <ul className="typeStyle">
        {types?.map((type, index) => (
          <li
            key={index}
            className={`listtype ${type === activeTab ? "active" : ""}`}
            onClick={() => onFilterChange(type)}
          >
            {type}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Filter;
