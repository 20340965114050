import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_API_URL } from "../config/apiURL/Base_Api";
import { handle500Error } from "../config/logout500/Handle500";

const useUserManagement = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("token1");
      const res = await axios.get(`${BASE_API_URL}/getCreatedUser`, {
        headers: {
          "Content-Type": "application/json",
          auth: token,
        },
      });

      if (res.data.status === 500) {
        handle500Error();
        return;
      }
      setData(res.data.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const deleteUser = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (confirmDelete) {
      try {
        const token = sessionStorage.getItem("token1");
        const res = await axios.delete(
          `${BASE_API_URL}/deleteSingleUser/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              auth: token,
            },
          }
        );
        if (res.data.status === 500) {
          handle500Error();
          return;
        }
        alert("User deleted successfully.");
        fetchData(); // Refetch user data after deletion
      } catch (error) {
        alert("Error deleting user: " + error.message);
      }
    }
  };

  const navigateToUser = (id) => {
    navigate(`/admin/user/${id}`);
  };

  return {
    data,
    loading,
    error,
    deleteUser,
    navigateToUser,
    fetchData,
  };
};

export default useUserManagement;
