import React from "react";

import "../pages/list/list.css";
import Sidebar from "../sidebar/Sidebar";

const withSideBarLayout = (Component) => {
  return () => (
    <div className="list">
      <div style={{ width: "18%" }}>
        <Sidebar />
      </div>
      <div className="listContainer">
        <Component />
      </div>
    </div>
  );
};

export default withSideBarLayout;
