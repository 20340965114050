function NavItem({ text, id, offset, handleClick }) {
  const handleClickEvent = () => {
    if (id) handleClick(id, offset);
  };

  return (
    <li className="nav-item">
      <button className="menubtn" onClick={handleClickEvent}>
        {text}
      </button>
    </li>
  );
}

export default NavItem;
