function useScrollToSection() {
  const scrollTo = (id, offset) => {
    const section = document.getElementById(id);

    if (section) {
      const yOffset = section.offsetTop - 10;
      window.scrollTo({ top: yOffset, behavior: "smooth" });
    }
  };

  return scrollTo;
}

export default useScrollToSection;
