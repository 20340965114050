import React, { useState } from "react";
import Modal from "react-modal";
import Button from "../utils/Button";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const AddRelationshipModal = ({ isOpen, onClose, onAdd }) => {
  const [formData, setFormData] = useState({
    name: "",
    male: "",
    female: "",
    other: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (
      formData.name.trim() === "" ||
      formData.male.trim() === "" ||
      formData.female.trim() === "" ||
      formData.other.trim() === ""
    ) {
      alert("All fields are required.");
      setIsSubmitting(false);
      return;
    }

    try {
      await onAdd(formData);
      setFormData({ name: "", male: "", female: "", other: "" });
    } catch (error) {
      alert(`Error: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Add Relationship Modal"
    >
      <h2>Add Relationship</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Male:</label>
          <input
            type="text"
            name="male"
            value={formData.male}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Female:</label>
          <input
            type="text"
            name="female"
            value={formData.female}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Other:</label>
          <input
            type="text"
            name="other"
            value={formData.other}
            onChange={handleChange}
            required
          />
        </div>
        <div className="bton-container">
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Adding..." : "Add"}
          </Button>
          <Button
            type="button"
            onClick={() => {
              onClose();
              setFormData({ name: "", male: "", female: "", other: "" });
            }}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddRelationshipModal;
