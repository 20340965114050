export const AboutUsData = [
  {
    Heading: "",
    Para: "Welcome to StrinZ, where positive thoughts and emotions get converted into powerful messages with the help of AI. Our journey began with a simple yet powerful vision: to connect emotion Strings with people across the globe effortlessly with the positive, private, and personal messaging. Today, we are proud to introduce you to our messaging app where positivity, convenience, and privacy reign supreme.",
  },
  {
    Heading: "Our Story",
    Para: "StrinZ is the brainchild of a team of visionary and dedicated developers, designers who shared a common dream: to create a messenger app that would redefine how we interact in this digital age. Our story started as a response to the lack of positivity and safety in existing messaging and social-media platforms. We believe that online communication should be positive, secure, and tailored to your preferences, and that is what drove us to build a platform that helps connect strings with people by spreading positivity across digital communication channels.",
  },
  {
    Heading: "Our Mission",
    Para: "At StrinZ, our mission is: to empower you with the tools you need to communicate, connect, effortlessly even without the hassle of curating the messages. We are committed to breaking down communication barriers and making messaging a positive, delightful experience for everyone around us.",
  },
  {
    Heading: "What Sets Us Apart ?",
    Points: [
      "Security First: Your privacy and data security are our top priorities. With state-of-the-art encryption and robust privacy features, you can trust that your conversations are always private and safe.",
      "Hassle-free Message Creation: Our AI powered messaging system helps you to create a powerful message to express your emotions with your loved ones.",
      "User-Centric Design: Our user-friendly interface is designed to make your messaging experience smooth and enjoyable. We believe in simple, intuitive design that puts you in control.",
      "Innovation at Its Core: We are continually exploring new ways to enhance your messaging experience. Expect regular updates, new features, and cutting-edge technology.",
      "Customization: We understand that every individual is unique and each relationship is different. That's why we offer a range of customization options, so you can tailor the app to your likings and preferences.",
    ],
  },
  {
    Heading: "Why Choose Us",
    Para: "Seamless Communication: Enjoy instant message creation based on one-to-one relationship and emotion, options to choose your message from never ending list of messages, sharing messages with your friends, family, and loved ones at one place. Global Reach: Create a String by sharing positive messages, connecting with friends and family across borders and time zones without any hassle. Always Free: Our basic features are free, and we provide premium options to cater to your specific needs.",
  },
  {
    Heading: "",
    Para1: "Join Us on This Journey!",
    Para: "We are excited to have you as part of the StrinZ community. Our story is ongoing, and we invite you to be a part of it. Download our app today and experience messaging like never before. Lets connect, make new StrinZ with friends, family and loved ones. Thank you for choosing StrinZ. Together, we are redefining messaging for a positive, brighter, more connected future.",
  },
];
