import React from "react";
import Modal from "react-modal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./filter.css";
import useStickies from "./stickiesApi/useStickies";
import Button from "../utils/Button";
import StickerList from "./StickerList";
import StickerModal from "./StickyModal";
import Filter from "./Filter";
import TransactionPasswordModal from "../utils/TransModal";

Modal.setAppElement("#root");

const Stickers = () => {
  const {
    stickyType,
    setStickyType,
    selectedStickers,
    setSelectedStickers,
    activeTab,
    isModalOpen,
    types,
    stickers,
    loadingStickers,
    handleFileChange,
    handleDelete,
    handleSubmit,
    openModal,
    closeModal,
    handleTypeChange,
    handleCheckboxChange,
    permission,
    isTransPassModalOpen,
    setIsTransPassModalOpen,
    handleTransPassSubmit,
    transPassword,
    setTransPassword,
    loadingTransPass,
  } = useStickies();

  return (
    <div className="stickies">
      <div className="sticky">
        <h3>Stickers</h3>
        {permission === "write" && (
          <div className="button-container">
            <Button onClick={openModal} disabled={loadingStickers}>
              Add Sticker
            </Button>
            <Button
              onClick={handleDelete}
              disabled={selectedStickers.length === 0 || loadingStickers}
            >
              Delete Sticker
            </Button>
          </div>
        )}
      </div>

      <div className="tabs">
        <Filter
          types={types}
          onFilterChange={handleTypeChange}
          activeTab={activeTab}
        />
      </div>

      <StickerList
        stickers={stickers}
        loading={loadingStickers}
        selectedStickers={selectedStickers}
        setSelectedStickers={setSelectedStickers}
        onCheckboxChange={handleCheckboxChange}
      />

      <StickerModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        handleSubmit={handleSubmit}
        handleFileChange={handleFileChange}
        stickyType={stickyType}
        setStickyType={setStickyType}
        types={types}
        loadingStickers={loadingStickers}
      />
      <TransactionPasswordModal
        isOpen={isTransPassModalOpen}
        setIsTransPassModalOpen={setIsTransPassModalOpen}
        onSubmit={handleTransPassSubmit}
        transPassword={transPassword}
        setTransPassword={setTransPassword}
        loading={loadingTransPass}
        loadingStickers={loadingStickers}
      />
      <ToastContainer />
    </div>
  );
};

export default Stickers;
