import React from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import faqData from "../DATA/faqData";

const HomeFaq = () => {
  return (
    <div>
      <section className="wrapper" id="faq">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center p-3">
              <h2>Frequently Ask Questions</h2>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <Accordion>
                {faqData?.slice(0, 5)?.map((item) => (
                  <Accordion.Item eventKey={item.key} key={item.key}>
                    <Accordion.Header>{item.header}</Accordion.Header>
                    <Accordion.Body>{item.body}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
              <div className="viewall">
                <Link to="/faq" className="view">
                  View All
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeFaq;
