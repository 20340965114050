import React from "react";
import usePasswordChange from "./useChangePass";
import Button from "../utils/Button";
import Instruction from "../utils/Instruction";

const ChangeTransactionPassword = () => {
  const { formData, loading, error, handleChange, handleSubmit } =
    usePasswordChange("changeSelfTransPassword", {
      password: "transPass",
      confirmPassword: "confirmTransPass",
    });

  return (
    <div>
      <h2>Change Transaction Password</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Password</label>
          <input
            type="password"
            name="transPass"
            value={formData.transPass}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Confirm Password</label>
          <input
            type="text"
            name="confirmTransPass"
            value={formData.confirmTransPass}
            onChange={handleChange}
            required
          />
        </div>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <div className="bton-container">
          <Button type="submit" disabled={loading}>
            {loading ? "Updating..." : "Update"}
          </Button>
        </div>
      </form>
      <Instruction />
    </div>
  );
};

export default ChangeTransactionPassword;
