import React, { useState } from "react";
import { useFormik } from "formik";
import { validationSchema } from "./ValidationSchema";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Button from "../../utils/Button";
import { useToken } from "../../utils/useToken";
import { BASE_API_URL } from "../../config/apiURL/Base_Api";
import { handle500Error } from "../../config/logout500/Handle500";

const UserForm = ({ inputs, fetchData, navigate }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { token } = useToken();

  const formik = useFormik({
    initialValues: {
      userName: "",
      mobileNumber: "",
      countryCode: "",
      password: "",
      confirmPassword: "",
      transPass: "",
      confirmTransPass: "",
      permission: "read",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true);

      try {
        await formik.validateForm();
        if (!formik.isValid) {
          console.log("Validation errors:", formik.errors);
          setIsSubmitting(false);
          return;
        }

        const res = await axios.post(`${BASE_API_URL}/addUser`, values, {
          headers: {
            "Content-Type": "application/json",
            auth: token,
          },
        });
        if (res.data.status === 401) {
          handle500Error();
          return;
        }

        if (res.data.status === 400) {
          alert(res.data.data);
          return;
        }

        if (res.status === 200) {
          formik.resetForm();
          alert("User added successfully!");
          fetchData();
          navigate("/admin/user/users");
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {inputs.map((input) => (
        <div className="formInput" key={input.id}>
          <label>{input.label}</label>
          {input.type === "tel" ? (
            <PhoneInput
              country={"us"}
              value={`${formik.values.countryCode}${formik.values.mobileNumber}`}
              onChange={(value, country) => {
                formik.setFieldValue(
                  "mobileNumber",
                  value.slice(country.dialCode.length)
                );
                formik.setFieldValue("countryCode", `+${country.dialCode}`);
              }}
              onBlur={formik.handleBlur}
              inputProps={{
                name: "mobileNumber",
                required: true,
                autoFocus: true,
              }}
              containerClass="phoneInputContainer"
              inputClass="phoneInput"
              buttonClass="phoneInputButton"
            />
          ) : (
            <>
              {input.name === "confirmPassword" ||
              input.name === "confirmTransPass" ? (
                <input
                  type="text" // Change to text type
                  placeholder={input.placeholder}
                  name={input.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[input.name]}
                />
              ) : (
                <input
                  type={input.type}
                  placeholder={input.placeholder}
                  name={input.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[input.name]}
                />
              )}
            </>
          )}
          {formik.touched[input.name] && formik.errors[input.name] ? (
            <div className="error">{formik.errors[input.name]}</div>
          ) : null}
        </div>
      ))}
      <div className="formInput">
        <label>Permission</label>
        <select
          name="permission"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.permission}
        >
          <option value="read">Read</option>
          <option value="write">Write</option>
        </select>
        {formik.touched.permission && formik.errors.permission ? (
          <div className="error">{formik.errors.permission}</div>
        ) : null}
      </div>
      <div>
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Adding..." : "Add"}
        </Button>
      </div>
    </form>
  );
};

export default UserForm;
