import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../../App.css";
import WEBLOGO from "../../../assets/strinz-logo.png";
import QrModal from "../../QRModal/QrModal";
import useScrollToSection from "./useScroll";
import { navItems } from "../../../Constant/HeaderData";
import NavItem from "./NavItems";
import Downbtn from "../../DownloadBtn/Downbtn";

export default function Header() {
  const { pathname } = useLocation();
  const scrollTo = useScrollToSection();
  const navRef = useRef(null);

  const handleClick = (id, offset) => {
    scrollTo(id, offset);
    if (navRef.current) navRef.current.classList.remove("show");
  };

  return (
    <div className="head">
      <nav className="navbar navbar-expand-sm navbar-light bg-light navbar-opacity">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src={WEBLOGO} alt="BigCo Inc. logo" style={{ width: "70%" }} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContent"
            ref={navRef}
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              {pathname === "/" && (
                <>
                  {navItems.map((item, index) => (
                    <NavItem key={index} {...item} handleClick={handleClick} />
                  ))}
                </>
              )}
              <li>
                {" "}
                <Downbtn />
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <QrModal />
    </div>
  );
}
