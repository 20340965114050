import React from "react";

import "./RelationShipTable.css";
import RelationshipDataGrid from "./cutom-hook/RelationsDatagrid";

const RelationshipList = (props) => {
  return <RelationshipDataGrid {...props} />;
};

export default RelationshipList;
