import React from "react";

import { Card } from "react-bootstrap";

const StickerCard = ({ sticker }) => {
  const extractNameFromUrl = (url) => {
    const parts = url.split("/");
    return parts[parts.length - 1];
  };

  const stickerName = extractNameFromUrl(sticker);

  return (
    <Card style={{ width: "10rem" }}>
      <Card.Img variant="top" src={sticker} />
      <Card.Body>
        <Card.Text>{stickerName}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default StickerCard;
