import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import "./styling/MessageList.css";

const MessageGrid = ({
  messages,
  selectedMessageId,
  handleRadioChange,
  handleEditMessage,
  handleDeleteMessage,
  loadingMessages,

  permission,
  currentPage,
  messagesPerPage,
}) => {
  const rows = messages.map((message, index) => ({
    id: message.library_id,
    index: (currentPage - 1) * messagesPerPage + index + 1,
    ...message,
  }));

  const columns = [
    {
      field: "select",
      headerName: "Select",
      width: 120,
      headerAlign: "center",
      renderCell: ({ row }) => (
        <input
          type="radio"
          checked={selectedMessageId === row.id}
          onChange={() => handleRadioChange(row.id)}
          disabled={loadingMessages}
          style={{ cursor: "pointer" }}
        />
      ),
    },
    { field: "index", headerName: "Index", width: 110 },
    {
      field: "message",
      headerName: "Message",
      width: 670,
      headerAlign: "center",
      renderCell: ({ row }) => <div className="messageCell">{row.message}</div>,
    },
    {
      field: "action",
      headerName: "Action",
      width: 140,
      renderCell: ({ row }) =>
        selectedMessageId === row.id && permission === "write" ? (
          <div className="cellAction">
            <span className="viewButton" onClick={() => handleEditMessage(row)}>
              Edit
            </span>

            <span
              className="deleteButton"
              onClick={() => handleDeleteMessage(row.id)}
            >
              Delete
            </span>
          </div>
        ) : null,
    },
  ];

  return (
    <div className="messageTableContainer">
      <DataGrid
        className="datagrid1"
        style={{ overflowX: "hidden" }}
        rows={rows}
        columns={columns}
        pagination={false}
        hideFooter
        autoHeight
        getRowId={(row) => row.id}
        components={{
          noRowsOverlay: () => (
            <div className="no-messages">No messages found...</div>
          ),
        }}
      />
    </div>
  );
};

export default MessageGrid;
