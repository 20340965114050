import React from "react";

const Instruction = () => {
  return (
    <div>
      <p style={{ color: "red", margin: "2%" }}>
        Password must be 8 char long, must contains atleast 1 capital letter,
        special characters are not allowed.
      </p>
    </div>
  );
};

export default Instruction;
