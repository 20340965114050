import React from "react";
import CommonHeader from "../Common/CommonHeade";
import { useScrollToTop } from "../useScrollToTop";
import "react-toastify/dist/ReactToastify.css";
import "../Common/Common.css";
import { ToastContainer, toast } from "react-toastify";

export default function Contact() {
  const handleSubmit = (e) => {
    e.preventDefault();

    toast.error(
      "Oops! Something went wrong on our end. Please try again later or contact support if the issue persists.",
      {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
  };
  useScrollToTop();
  return (
    <div className="wrapper">
      <div className="wrap">
        <CommonHeader children="Contact Us!" />
        <section className="Contact">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2>Contact StrinZ</h2>
                <h4>Phone Number</h4>
                <p>
                  Please provide the phone number you use for your WhatsApp
                  account.
                </p>
                <form>
                  <div className="mb-3">
                    <label htmlFor="inputPhone" className="form-label">
                      Phone number
                    </label>
                    <div className="input-group">
                      <select
                        className="custom-select"
                        id="countryCode"
                        required=""
                      >
                        <option value={+1}>+1 (USA)</option>
                        <option value={+44}>+44 (UK)</option>
                      </select>
                      <input
                        type="tel"
                        className="form-control"
                        id="inputPhone"
                        placeholder="123-456-7890"
                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        required=""
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="inputEmail" className="form-label">
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="inputEmail"
                      placeholder="name@example.com"
                      required=""
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="inputEmail" className="form-label">
                      Confirm Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="inputEmail"
                      placeholder="name@example.com"
                      required=""
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="inputEmail" className="form-label">
                      Please enter your message below.
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows={3}
                      defaultValue={""}
                    />
                  </div>
                  <div className="read-btn">
                    <button
                      type="submit"
                      className="read"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </div>
  );
}
