import React from "react";

const StickyTypeDropdown = ({ stickyType, setStickyType, types }) => {
  return (
    <div>
      <label htmlFor="stickyType">Sticky Type:</label>
      <select
        id="stickyType"
        value={stickyType}
        onChange={(e) => setStickyType(e.target.value)}
      >
        <option value="">Select Sticky Type</option>
        {types?.map((type, index) => (
          <option key={index} value={type}>
            {type}
          </option>
        ))}
      </select>
    </div>
  );
};

export default StickyTypeDropdown;
