import React, { useEffect, useState } from "react";
import axios from "axios";
import "./LoginForm.css";
import { useNavigate } from "react-router-dom";
import Button from "../utils/Button";
import { BASE_API_URL } from "../config/apiURL/Base_Api";
const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const data = {
      userName: username,
      password: password,
    };

    try {
      const response = await axios.post(`${BASE_API_URL}/login`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const { accessToken, otpVerify } = response.data;

        sessionStorage.setItem("token", accessToken);
        sessionStorage.setItem("id", response.data.updatedItem.id);
        if (otpVerify) {
          navigate("/otp");
        } else {
          sessionStorage.setItem("token1", response.data.accessToken);
          sessionStorage.setItem("user", response.data.updatedItem.role);
          sessionStorage.setItem(
            "permission",
            response.data.updatedItem.permission
          );

          navigate("/admin/user/home");
        }
      } else {
        throw new Error("Login failed.");
      }
    } catch (error) {
      console.log("Login Error:", error);
      alert(error.response.data.message);
    } finally {
      setIsSubmitting(false);
      setUsername("");
      setPassword("");
    }
  };

  useEffect(() => {
    if (token) {
      navigate("/admin/user/home");
    }
  }, [token, navigate]);

  return (
    <div className="login-wrapper">
      <div className="login-container">
        <form id="login-form" onSubmit={handleSubmit}>
          <h2>Login</h2>
          <div className="input-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <Button type="submit" disabled={isSubmitting} className="log-button">
            {isSubmitting ? "Sending request..." : "Login"}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
