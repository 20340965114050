import React, { useState, useEffect } from "react";
import { FaArrowCircleUp, FaArrowUp } from "react-icons/fa";
import "./ScrollUp.css";

const ScrollUp = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div
      onClick={handleClick}
      className={`scrollUp ${isVisible ? "fade-in" : "fade-out"}`}
    >
      <span>
        <FaArrowUp />
      </span>
    </div>
  );
};

export default ScrollUp;
