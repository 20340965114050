import OtpVerification from "../../authentication/Otp";
import Cover from "./Cover";
import "./login.css";

const Otp = () => {
  return (
    <div className="login-container">
      <div className="login-inner">
        <Cover />
        <OtpVerification />
      </div>
    </div>
  );
};

export default Otp;
