import React from "react";

import CommonHeader from "../Common/CommonHeade";
import { useScrollToTop } from "../useScrollToTop";
import "../Common/Common.css";
import { EulaData } from "../DATA/EulaData";

const EULA = () => {
  useScrollToTop();
  return (
    <div className="wrapper">
      <div className="wrap">
        <CommonHeader children={"End User License Agreement"} />{" "}
        {EulaData?.map((eula) => (
          <div key={eula?.key}>{eula?.body}</div>
        ))}
      </div>
    </div>
  );
};

export default EULA;
