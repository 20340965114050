import React, { useState } from "react";

import LoadingSpinner from "../utils/Spinner";

import TransactionPasswordModal from "../utils/TransModal";
import AddRelationshipModal from "./AddRelationShip";
import EditRelationshipModal from "./UpdateRelationShip";
import RelationshipList from "./RelationList";
import useRelationships from "./cutom-hook/useRelationship";

const RelationshipTable = () => {
  const {
    relationships,

    selectedId,
    loading,
    token,
    isModalOpen,
    deletingId,
    editingRelationship,
    isEditModalOpen,
    permission,
    handleRadioChange,
    handleAdd,
    openModal,
    closeModal,
    handleEdit,
    handleUpdate,
    handleDelete,
    setEditingRelationship,
  } = useRelationships();

  const [isTransPassModalOpen, setIsTransPassModalOpen] = useState(false);
  const [loadingTransPass, setLoadingTransPass] = useState(false);
  const [currentOperation, setCurrentOperation] = useState(null);
  const [currentOperationData, setCurrentOperationData] = useState(null);

  const openTransPassModal = (operation, data) => {
    setCurrentOperation(operation);
    setCurrentOperationData(data);
    setIsTransPassModalOpen(true);
  };
  const handleTransPassSubmit = async (transPassword) => {
    setLoadingTransPass(true);
    try {
      if (currentOperation === "add") {
        await handleAdd(currentOperationData, transPassword);
      } else if (currentOperation === "edit") {
        await handleUpdate(currentOperationData, transPassword);
      } else if (currentOperation === "delete") {
        await handleDelete(currentOperationData, transPassword);
      }
      closeModal();
    } catch (error) {
      alert(`Error ${currentOperation}ing relationship: ${error.message}`);
    } finally {
      setIsTransPassModalOpen(false);
      setLoadingTransPass(false);
    }
  };

  return (
    <div className="relationship-table">
      <h2>Relationships</h2>
      <div
        style={{
          display: "flex",
          fontSize: "1rem",
          justifyContent: "center",
        }}
      >
        {permission === "write" && (
          <div className="download" onClick={() => openModal("add")}>
            Add Relationship
          </div>
        )}
      </div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <RelationshipList
          relationships={relationships}
          selectedId={selectedId}
          handleRadioChange={handleRadioChange}
          handleEdit={handleEdit}
          handleDelete={(id) => openTransPassModal("delete", id)}
          deletingId={deletingId}
          permission={permission}
        />
      )}
      <AddRelationshipModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onAdd={(data) => openTransPassModal("add", data)}
        relationships={relationships}
        token={token}
      />
      {editingRelationship && (
        <EditRelationshipModal
          isOpen={isEditModalOpen}
          relationship={editingRelationship}
          onClose={() => setEditingRelationship(null)}
          onUpdate={(data) => openTransPassModal("edit", data)}
          token={token}
        />
      )}
      <TransactionPasswordModal
        isOpen={isTransPassModalOpen}
        setIsTransPassModalOpen={setIsTransPassModalOpen}
        onSubmit={handleTransPassSubmit}
        loading={loadingTransPass}
      />
    </div>
  );
};

export default RelationshipTable;
