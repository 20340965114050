import React from "react";
import "../stickys/filter.css";
import { Card } from "react-bootstrap";

const BackGroundCard = ({ backGround }) => {
  const extractNameFromUrl = (url) => {
    const parts = url.split("/");
    return parts[parts.length - 1];
  };

  const stickerName = extractNameFromUrl(backGround);

  return (
    <Card style={{ width: "15rem" }}>
      <Card.Img variant="top" src={backGround} />
      <Card.Body>
        <Card.Text>{stickerName}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default BackGroundCard;
