import React from "react";
import ChangePasswordModal from "./ChangePasswordModal";

const ChangeTransPasswordModal = ({ isOpen, onClose, userId }) => {
  return (
    <ChangePasswordModal
      isOpen={isOpen}
      onClose={onClose}
      userId={userId}
      title="Transaction Password"
      passwordField="transPass"
      confirmField="confirmTransPass"
      endpoint="changeTransPassword"
      successMessage="Transaction password changed successfully!"
      errorMessage="Failed to change transaction password. Please check your inputs and try again."
    />
  );
};

export default ChangeTransPasswordModal;
