import React from "react";
import usePasswordChange from "./useChangePass";
import Button from "../utils/Button";
import Instruction from "../utils/Instruction";

const ChangeLoginPass = () => {
  const { formData, error, loading, handleChange, handleSubmit } =
    usePasswordChange("changeSelfPassword", {
      password: "password",
      confirmPassword: "confirmPassword",
    });

  return (
    <>
      <div>
        <h2>Change Password</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Password</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Confirm Password</label>
            <input
              type="text"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
          </div>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <div className="bton-container">
            <Button type="submit" disabled={loading}>
              {loading ? "Updating..." : "Update"}
            </Button>
          </div>
        </form>
        <Instruction />
      </div>
    </>
  );
};

export default ChangeLoginPass;
