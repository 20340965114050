import React, { useState } from "react";
import Sidebar from "../../sidebar/Sidebar";
import "./single.css";
import "../../messageList/styling/RelationshipData.css";
import LoadingSpinner from "../../utils/Spinner";
import useSingleUser from "./useSingelUser";
import Button from "../../utils/Button";
import ChangeLoginPasswordModal from "../../authentication/changeUserPass/ChangeUserLogin";
import ChangeTransPasswordModal from "../../authentication/changeUserPass/ChangeUserTrans";

const Single = () => {
  const {
    user,
    loading,
    error,
    editMode,
    newPermission,
    handleEdit,
    handleSave,
    handleCancel,
    handleChangePermission,
  } = useSingleUser();

  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isTransModalOpen, setTransModalOpen] = useState(false);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div className="single">
      <div style={{ width: "18%" }}>
        <Sidebar />
      </div>
      <div className="singleContainer">
        <div className="top">
          <div className="left">
            {!editMode ? (
              <div
                style={{
                  display: "flex",
                  gap: "15px",
                  justifyContent: "flex-end",
                }}
              >
                <Button className="download" onClick={handleEdit}>
                  Edit
                </Button>
                <div>
                  <Button onClick={() => setLoginModalOpen(true)}>
                    Login Pass
                  </Button>
                </div>
                <div>
                  <Button onClick={() => setTransModalOpen(true)}>
                    Trans Pass
                  </Button>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "15px",
                }}
              >
                <Button className="download" onClick={handleSave}>
                  Save
                </Button>
                <Button className="download" onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
            )}
            <h1 className="title">Information</h1>
            <div className="item">
              <div className="details">
                <h1 className="itemTitle">{user.userName}</h1>
                <div className="detailItem">
                  <span className="itemKey">Phone:</span>
                  <span className="itemValue">
                    {user.countryCode} {user.mobileNumber}
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Permission:</span>
                  {editMode ? (
                    <select
                      value={newPermission}
                      onChange={(e) => handleChangePermission(e.target.value)}
                    >
                      {user.permission === "write" ? (
                        <>
                          <option value="write">write</option>
                          <option value="read">read</option>
                        </>
                      ) : (
                        <>
                          <option value="read">read</option>
                          <option value="write">write</option>
                        </>
                      )}
                    </select>
                  ) : (
                    <span className="itemValue">{user.permission}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ChangeLoginPasswordModal
        isOpen={isLoginModalOpen}
        onClose={() => setLoginModalOpen(false)}
        userId={user.id}
      />

      <ChangeTransPasswordModal
        isOpen={isTransModalOpen}
        onClose={() => setTransModalOpen(false)}
        userId={user.id}
      />
    </div>
  );
};

export default Single;
