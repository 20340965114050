import React, { useState } from "react";
import Modal from "react-modal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { usePermission, useToken } from "../utils/useToken";
import useBackgroundData from "./useBackground";

import TransactionPasswordModal from "../utils/TransModal";
import BackgroundList from "./BacgroundList";
import AddBackgroundModal from "./AddBackground";

Modal.setAppElement("#root");

const Background = () => {
  const { token } = useToken();
  const { permission } = usePermission();
  const { backGround, loading, deleteBackground, uploadBackground } =
    useBackgroundData(token);
  const [selectedBack, setSelectedBack] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTransPassModalOpen, setIsTransPassModalOpen] = useState(false);
  const [transPassword, setTransPassword] = useState("");
  const [currentAction, setCurrentAction] = useState(null);

  const handleDelete = () => {
    if (selectedBack.length === 0) {
      toast.error("No Background selected.");
      return;
    }
    if (
      !window.confirm(
        "Are you sure you want to delete the selected Background?"
      )
    ) {
      return;
    }
    setCurrentAction("delete");
    setIsTransPassModalOpen(true);
  };
  const handleUpload = (file) => {
    setCurrentAction({ action: "upload", file });
    setIsTransPassModalOpen(true);
  };

  const handleTransPassSubmit = async (password) => {
    if (currentAction === "delete") {
      await deleteBackground(selectedBack, password);
      setSelectedBack([]);
    } else if (currentAction.action === "upload") {
      await uploadBackground(currentAction.file, password);
    }
    setIsTransPassModalOpen(false);
    setTransPassword("");
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCheckboxChange = (backUrl) => {
    setSelectedBack((prevSelected) =>
      prevSelected.includes(backUrl)
        ? prevSelected.filter((s) => s !== backUrl)
        : [...prevSelected, backUrl]
    );
  };

  return (
    <div className="stickies">
      <div className="sticky">
        <h3>Background</h3>
        {permission === "write" && (
          <div className="button-container">
            <button className="download" onClick={openModal} disabled={loading}>
              Add Background
            </button>
            <button
              className="download"
              onClick={handleDelete}
              disabled={loading}
            >
              Delete Selected
            </button>
          </div>
        )}
      </div>

      <BackgroundList
        backGround={backGround}
        loading={loading}
        selectedBack={selectedBack}
        setSelectedBack={setSelectedBack}
        onCheckboxChange={handleCheckboxChange}
      />

      <AddBackgroundModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        uploadBackground={(file) => handleUpload(file)}
      />

      <TransactionPasswordModal
        isOpen={isTransPassModalOpen}
        setIsTransPassModalOpen={setIsTransPassModalOpen}
        onSubmit={handleTransPassSubmit}
        transPassword={transPassword}
        setTransPassword={setTransPassword}
        loading={loading}
      />

      <ToastContainer />
    </div>
  );
};

export default Background;
