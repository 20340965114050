import React, { useState } from "react";
import "./styling/RelationshipData.css";
import CustomModal from "../utils/Modal";
import Button from "../utils/Button";

const EditMessageModal = ({ message, onSave, isEditModalOpen, onClose }) => {
  const [editedMessage, setEditedMessage] = useState({ ...message });
  const [submitting, setSubmitting] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedMessage({ ...editedMessage, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);

    try {
      await onSave(editedMessage); // Assuming onSave function handles API call or state update
      onClose();
    } catch (error) {
      console.error("Failed to update message:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <CustomModal
      isOpen={isEditModalOpen}
      onRequestClose={onClose}
      title={`Edit Message for ${message.relation_name}`}
    >
      <form onSubmit={handleSubmit}>
        <div>
          <label>ID</label>
          <input
            style={{ color: "grey" }}
            type="text"
            name="id"
            value={editedMessage.library_id}
            onChange={handleInputChange}
            disabled
          />
        </div>
        <div>
          <label>Emotion</label>
          <input
            style={{ color: "grey" }}
            type="text"
            name="emotion"
            value={editedMessage.emotion}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Relationship ID</label>
          <input
            style={{ color: "grey" }}
            type="text"
            name="relationshipId"
            value={editedMessage.relationship_id}
            onChange={handleInputChange}
            disabled
          />
        </div>
        <div>
          <label>Message</label>
          <textarea
            style={{ width: "100%" }}
            name="message"
            value={editedMessage.message}
            onChange={handleInputChange}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
          <Button type="submit" className="custom-Button">
            {submitting ? "Saving..." : "Save"}
          </Button>
          <Button
            type="button"
            onClick={onClose}
            className="custom-button"
            disabled={submitting}
          >
            Cancel
          </Button>
        </div>
      </form>
    </CustomModal>
  );
};

export default EditMessageModal;
