import React from "react";
import "./styling/MessageList.css";

const RelationshipSelector = ({
  relationships,
  emotions,
  selectedRelation,
  selectedEmotion,
  onRelationChange,
  onEmotionChange,
}) => {
  return (
    <>
      <div className="select-container">
        <select value={selectedRelation} onChange={onRelationChange}>
          <option value="">Relationships</option>
          {relationships &&
            relationships?.map((relation) => (
              <option key={relation.RelationId} value={relation.RelationId}>
                {relation.RelationName}
              </option>
            ))}
        </select>

        <select
          value={selectedEmotion}
          onChange={onEmotionChange}
          disabled={!selectedRelation}
        >
          <option value="">Emotion</option>
          {selectedRelation &&
            emotions?.map((emotion, index) => (
              <option key={index} value={emotion.value}>
                {emotion.label}
              </option>
            ))}
        </select>
      </div>
    </>
  );
};

export default RelationshipSelector;
