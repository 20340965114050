import React, { useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import Button from "../utils/Button";
import { validateFile } from "./Validate";
import { customModalStyles } from "../utils/customModalStyles";

const AddBackgroundModal = ({ isOpen, onRequestClose, uploadBackground }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      toast.error("Please select a file.");
      return;
    }
    try {
      await validateFile(file);
    } catch (validationError) {
      toast.error(validationError);
      return;
    }
    try {
      setLoading(true);
      await uploadBackground(file);

      onRequestClose();
      setFile(null);
    } catch (error) {
      console.error("Error handling file upload:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customModalStyles}
      contentLabel="Upload Background Modal"
    >
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="file">File:</label>
          <input
            type="file"
            id="file"
            accept="image/png"
            onChange={handleFileChange}
          />
        </div>
        <div className="bton-container">
          <Button type="submit" disabled={loading} className="download">
            {loading ? "Uploading..." : "Upload"}
          </Button>
          <Button
            type="button"
            className="download"
            onClick={() => {
              onRequestClose();
              setFile(null);
            }}
            disabled={loading}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddBackgroundModal;
