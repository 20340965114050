import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AddFAQ from "./AddFaq";
import EditFAQ from "./UpdateFaq";
import DeleteFAQ from "./DeleteFaq";
import { usePermission } from "../utils/useToken";
import useFAQ from "./policiesHook/useFaq";
import TransactionPasswordModal from "../utils/TransModal";

const FaqEdit = () => {
  const { faqs, loading, addFAQ, updateFAQ, deleteFAQ } = useFAQ();
  const { permission } = usePermission();
  const [isTransPassModalOpen, setIsTransPassModalOpen] = useState(false);
  const [loadingTransPass, setLoadingTransPass] = useState(false);
  const [currentOperation, setCurrentOperation] = useState(null);
  const [currentOperationData, setCurrentOperationData] = useState(null);
  const loadingPlaceholder = "<p>Loading FAQs...</p>";
  const openTransPassModal = (operation, data) => {
    setCurrentOperation(operation);
    setCurrentOperationData(data);
    setIsTransPassModalOpen(true);
  };
  const handleTransPassSubmit = async (transPassword) => {
    setLoadingTransPass(true);
    try {
      if (currentOperation === "add") {
        await addFAQ(currentOperationData, transPassword);
      } else if (currentOperation === "edit") {
        await updateFAQ(currentOperationData, transPassword);
      } else if (currentOperation === "delete") {
        await deleteFAQ(currentOperationData, transPassword);
      }
    } catch (error) {
      alert(`Error ${currentOperation}ing relationship: ${error.message}`);
    } finally {
      setIsTransPassModalOpen(false);
      setLoadingTransPass(false);
    }
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "5px",
        }}
      >
        <h3>Edit FAQ</h3>
        <div style={{ display: "flex", gap: "10px" }}>
          {permission === "write" && (
            <>
              <AddFAQ onAdd={(data) => openTransPassModal("add", data)} />
              <EditFAQ
                faqs={faqs}
                onUpdate={(data) => openTransPassModal("edit", data)}
              />
              <DeleteFAQ
                faqs={faqs}
                onDelete={(id) => openTransPassModal("delete", id)}
              />
            </>
          )}
        </div>
      </div>

      <div className="custom-editor">
        <ReactQuill
          value={
            loading
              ? loadingPlaceholder
              : faqs
                  .map(
                    (faq) =>
                      `<p><strong>Question:</strong> ${faq.question}</p><p><strong>Answer:</strong> ${faq.answer}</p>`
                  )
                  .join("<hr>")
          }
          modules={{ toolbar: false }}
          formats={formats}
          readOnly
        />
      </div>
      <TransactionPasswordModal
        isOpen={isTransPassModalOpen}
        setIsTransPassModalOpen={setIsTransPassModalOpen}
        onSubmit={handleTransPassSubmit}
        loading={loadingTransPass}
      />
    </>
  );
};

export default FaqEdit;

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];
