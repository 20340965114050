import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const [token, setToken] = useState(
    sessionStorage.getItem("token1" || "token")
  );
  useEffect(() => {
    const storedToken = sessionStorage.getItem("token1" || "token");
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  if (token) {
    return <Outlet />;
  } else {
    return <Navigate to="/" replace />;
  }
};

export default PrivateRoute;
