import React from "react";
import CustomModal from "../utils/Modal";
import "./styling/RelationshipData.css";
import Button from "../utils/Button";

const AddMessageModal = ({ isOpen, onRequestClose, handleAddMessages }) => {
  const [file, setFile] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [submitting, setSubmitting] = React.useState(false);

  const fileInputRef = React.useRef(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleSubmit = async () => {
    if (!file) {
      setError("Please select a file.");
      return;
    }

    try {
      setSubmitting(true);
      // Simulate API call or any async operation
      await handleAddMessages(file);
      // After successful submission, close the modal
      onRequestClose();
      setFile(null);
    } catch (error) {
      console.error("Error adding messages:", error);
      setError("Failed to add messages. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={`Add Messages by file.`}
    >
      <div>
        <label>
          Choose File:
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            ref={fileInputRef}
            required
          />
        </label>
        {error && <p style={{ color: "red" }}>{error}</p>}
      </div>
      <div className="bton-container">
        <Button onClick={handleSubmit}>
          {submitting ? "Submitting..." : "Submit"}
        </Button>
        <Button
          onClick={() => {
            onRequestClose();
            setError("");
          }}
          disabled={submitting}
        >
          Close
        </Button>
      </div>
    </CustomModal>
  );
};

export default AddMessageModal;
