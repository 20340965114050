import React from "react";
import CommonHeader from "../Common/CommonHeade";
import "./join.css";
import { useScrollToTop } from "../useScrollToTop";
const JoinUs = () => {
  useScrollToTop();
  return (
    <div className="wrapper">
      <div className="wrap">
        <CommonHeader children={"Join Us!"} />
        <div className="join">
          <span>
            🌟 Exciting news! Job openings coming soon. Join our team and be
            part of something amazing! Stay tuned for updates. 🚀
          </span>
        </div>
      </div>
    </div>
  );
};

export default JoinUs;
