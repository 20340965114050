import React from "react";

import "../stickys/filter.css";

import BackGroundCard from "./BackGroundCard";
import LoadingSpinner from "../utils/Spinner";

const BackgroundList = ({
  backGround,
  loading,
  setSelectedBack,
  selectedBack,
}) => {
  const handleCheckboxChange = (backGround) => {
    if (selectedBack?.includes(backGround)) {
      setSelectedBack(selectedBack?.filter((s) => s !== backGround));
    } else {
      setSelectedBack([...selectedBack, backGround]);
    }
  };

  return (
    <div className="sticker-list">
      {loading ? (
        <div>
          <LoadingSpinner />
        </div>
      ) : (
        backGround?.map((backGround, index) => (
          <div key={index} className="sticker-item">
            <input
              type="checkbox"
              checked={selectedBack?.includes(backGround)}
              onChange={() => handleCheckboxChange(backGround)}
              className="sticker-checkbox"
            />
            <BackGroundCard backGround={backGround} key={index} />
          </div>
        ))
      )}
    </div>
  );
};

export default BackgroundList;
