import React from "react";
import { Link } from "react-router-dom";
import WEBLOGO from "../../../assets/strinz-logo.png";
import { footerLinks } from "../../../Constant/FooterData";
import "./Footer.css";
import { useScrollToTop } from "../../useScrollToTop";

function Footer() {
  const socialMediaIcons = [
    {
      icon: "fa-brands fa-facebook-f",
      link: "https://www.facebook.com/profile.php?id=61557938786749",
    },
    { icon: "fa-brands fa-x-twitter" },
    {
      icon: "fa-brands fa-instagram",
      link: "https://www.instagram.com/strinz.app/",
    },
  ];
  useScrollToTop();
  return (
    <>
      <div className="footerContainer">
        <div className="footerSection logoSection">
          <Link to="/">
            <img src={WEBLOGO} alt="BigCo Inc. logo" className="logoImage" />
          </Link>
        </div>
        <div className="footerSection linksSection">
          <ul className="footerLinks">
            {footerLinks.map((link) => (
              <li key={link?.path} className="footerLinkItem">
                <Link to={link?.path} className="footerLink">
                  {link?.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="footerSection socialSection">
          <span>Follow us on</span>
          <div className="socialIcons">
            {socialMediaIcons?.map((icon) => (
              <>
                <Link
                  to={icon.link}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <i key={icon.icon} className={icon.icon}></i>
                </Link>
              </>
            ))}
          </div>
        </div>
      </div>
      <div className="copyRight">
        <p>© StrinZ. 2024.</p>
      </div>
    </>
  );
}
export default Footer;
