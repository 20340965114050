// Privacy.jsx
import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./Privacy.scss";
import AddPrivacyPolicy from "./AddPrivacy";
import { usePermission, useToken } from "../utils/useToken";
import { fetchPrivacyPolicyApi } from "./api/privacyApi";

const Privacy = () => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { permission } = usePermission();
  const { token } = useToken();

  const fetchPrivacyPolicy = async () => {
    setError("");
    setLoading(true);
    const { error, value: fetchedValue } = await fetchPrivacyPolicyApi(token);
    if (error) {
      setError(error);
    } else {
      setValue(fetchedValue);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchPrivacyPolicy();
    }
  }, [token]);

  const loadingPlaceholder = "<p>Loading Privacy Policy...</p>";

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "1%",
        }}
      >
        <h3>Privacy Policy</h3>
        {permission === "write" && (
          <AddPrivacyPolicy
            token={token}
            onUpdate={fetchPrivacyPolicy}
            value={value}
            setValue={setValue}
          />
        )}
      </div>
      {error && <p className="error-message">{error}</p>}
      <div className="custom-editor">
        <ReactQuill
          value={loading ? loadingPlaceholder : value}
          readOnly={true}
          modules={{ toolbar: false }}
          // formats={formats}
        />
      </div>
    </>
  );
};

export default Privacy;
