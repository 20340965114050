import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { sidebarItems } from "../constants/sidebarItems";
import "./sidebar.css";
import SidebarList from "./SideBarList";

const Sidebar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    const confirmLogout = window.confirm("Do you want to logout?");
    if (!confirmLogout) {
      return;
    }
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("token1");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("permission");
    navigate("/admin");
  };

  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/user/home" style={{ textDecoration: "none" }}>
          <div className="logo">
            {/* <img src={strinLogo} alt="strinz" /> */}
          </div>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          {sidebarItems.slice(0, 1).map((item, index) => (
            <SidebarList key={index} {...item} />
          ))}
          <p className="title">LISTS</p>
          {sidebarItems.slice(1, 2).map((item, index) => (
            <SidebarList key={index} {...item} />
          ))}
          <p className="title">POLICIES</p>
          {sidebarItems.slice(2, 6).map((item, index) => (
            <SidebarList key={index} {...item} />
          ))}
          <p className="title">CATEGORY</p>
          {sidebarItems.slice(6, 10).map((item, index) => (
            <SidebarList key={index} {...item} />
          ))}
          <p className="title">USER</p>
          {sidebarItems.slice(10, 12).map((item, index) => (
            <SidebarList key={index} {...item} />
          ))}

          <p onClick={handleLogout} className="changePass">
            {" "}
            <div className="logOut">
              <span>
                <FiLogOut />
              </span>
              <span>Logout</span>
            </div>
          </p>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
