import React from "react";
import { MdArrowUpward, MdArrowDownward } from "react-icons/md";
import Button from "../utils/Button";
import AddMessageModal from "./Addmessage";
import { CSVLink } from "react-csv";

const AddMessageSection = ({
  setIsAddModalOpen,
  handleAddMessages,
  selectedRelation,
  selectedEmotion,
  isAddModalOpen,
}) => {
  const sampleFileData = [
    ["Relationship", "Emotions", "Messages"],
    ["Ex", "Love", "I'm over you!"],
  ];

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <Button
        onClick={() => setIsAddModalOpen(true)}
        disabled={!selectedRelation || !selectedEmotion}
      >
        <MdArrowUpward />
      </Button>
      <AddMessageModal
        isOpen={isAddModalOpen}
        onRequestClose={() => setIsAddModalOpen(false)}
        handleAddMessages={handleAddMessages}
        relation={selectedRelation}
        emotion={selectedEmotion}
      />
      <Button>
        <CSVLink
          data={sampleFileData}
          filename={"samplefile.csv"}
          className="csv-link"
          // target="_blank"
        >
          <MdArrowDownward style={{ color: "white" }} />
        </CSVLink>
      </Button>
    </div>
  );
};

export default AddMessageSection;
