// EULAedit.js

import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AddEULA from "./AddEula";
import { useToken } from "../utils/useToken";
import { fetchEULA } from "./api/eulaApi"; // Import API function

const EULAedit = () => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { token } = useToken();

  const fetchEULAContent = async () => {
    setError("");
    setLoading(true);
    const { value, error } = await fetchEULA(token);
    if (value) {
      setValue(value);
    } else {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchEULAContent();
    }
  }, [token]);

  const loadingPlaceholder = "<p>Loading EULA...</p>";

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "1%",
        }}
      >
        <h3>EULA</h3>
        <AddEULA
          token={token}
          onUpdate={fetchEULAContent}
          value={value}
          setValue={setValue}
        />
      </div>
      {error && <p className="error-message">{error}</p>}
      <div className="custom-editor">
        <ReactQuill
          value={loading ? loadingPlaceholder : value}
          readOnly={true}
          modules={{ toolbar: false }}
          formats={formats}
        />
      </div>
    </>
  );
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

export default EULAedit;
