import { MdDashboard, MdOutlinePassword } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { RiMessage2Fill } from "react-icons/ri";
import { TbBackground } from "react-icons/tb";
import { GiRelationshipBounds } from "react-icons/gi";
import { LuSticker } from "react-icons/lu";

import terms from "../imageassets/TnC.png";
import eula from "../imageassets/Privacy.png";
import help from "../imageassets/help.png";
import "./sidebarItems.css";
export const sidebarItems = [
  {
    to: "/admin/user/home",
    icon: <MdDashboard className="icons" />,
    text: "Dashboard",
  },
  {
    to: "/admin/user/users",
    icon: <FaUserCircle className="icons" />,
    text: "Users",
  },
  {
    to: "/admin/user/eulaPolicy",
    icon: <img src={eula} alt="EULA" className="icons" />,
    text: "EULA",
  },
  {
    to: "/admin/user/T&C",
    icon: <img src={terms} alt="Terms and Conditions" className="icons" />,
    text: "T & C",
  },
  {
    to: "/admin/user/privacy",
    icon: <img src={eula} alt="Privacy Policy" className="icons" />,
    text: "Privacy Policy",
  },
  {
    to: "/admin/user/FaqEdit",
    icon: <img src={help} alt="FAQ" className="icons" />,
    text: "FAQ",
  },
  {
    to: "/admin/user/messages",
    icon: <RiMessage2Fill className="icons" />,
    text: "Messages",
  },
  {
    to: "/admin/user/relations",
    icon: <GiRelationshipBounds className="icons" />,
    text: "Relations",
  },
  {
    to: "/admin/user/stickies",
    icon: <LuSticker className="icons" />,
    text: "Stickies",
  },
  {
    to: "/admin/user/background",
    icon: <TbBackground className="icons" />,
    text: "Background",
  },
  {
    to: "/admin/user/changePass",
    icon: <MdOutlinePassword className="icons" />,
    text: "Change Password",
  },
  {
    to: "/admin/user/changeTransPass",
    icon: <MdOutlinePassword className="icons" />,
    text: "Change TranPass",
  },
];
