// services/apiService.js

import axios from "axios";
import { BASE_API_URL } from "../../config/apiURL/Base_Api";
import { handle500Error } from "../../config/logout500/Handle500";

export const getStickerTypes = async (token) => {
  try {
    const response = await axios.get(`${BASE_API_URL}/adminGetStickerTypes`, {
      headers: {
        auth: token,
      },
    });
    if (response.data.status === 401) {
      handle500Error();
      return;
    }
    return response.data.data.data; // Assuming response structure has a 'data' property
  } catch (error) {
    throw new Error(`Failed to fetch sticker types: ${error.message}`);
  }
};

export const getStickersByType = async (token, type) => {
  try {
    const response = await axios.get(
      `${BASE_API_URL}/adminGetFolderSticker?type=${type}`,
      {
        headers: {
          auth: token,
        },
      }
    );
    if (response.data.status === 401) {
      handle500Error();
      return;
    }
    return response.data.data.data; // Assuming response structure has a 'data' property
  } catch (error) {
    throw new Error(`Failed to fetch stickers: ${error.message}`);
  }
};

export const uploadSticker = async (token, stickerType, file, transPass) => {
  try {
    const formData = new FormData();
    formData.append("stickerType", stickerType);
    formData.append("file", file);
    formData.append("transPass", transPass); // Include transaction password in formData

    const response = await axios.post(
      `${BASE_API_URL}/adminUploadSticker`,
      formData,
      {
        headers: {
          auth: token,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.data.status === 401) {
      handle500Error();
      return;
    }
    return response.data;
  } catch (error) {
    throw new Error(`Failed to upload sticker: ${error.message}`);
  }
};

export const deleteStickers = async (token, fileUrls, transPass) => {
  try {
    const response = await axios.delete(`${BASE_API_URL}/adminDeleteSticker`, {
      headers: {
        auth: token,
      },
      data: {
        fileUrls,
        transPass: transPass, // Include transaction password in data
      },
    });

    if (response.data.status === 401) {
      handle500Error();
      return;
    }
    return response.data; // Assuming you return useful data upon successful deletion
  } catch (error) {
    throw new Error(
      `Failed to delete stickers: ${error.response.data.message}`
    );
  }
};
