import React from "react";
import CommonHeader from "../Common/CommonHeade";
import { useScrollToTop } from "../useScrollToTop";
import { PrivacyData } from "../DATA/PrivacyData";
import "../Common/Common.css";
const PrivacyPolicy = () => {
  useScrollToTop();
  return (
    <div className="wrapper">
      <div className="wrap">
        <CommonHeader children={"Privacy Policy"} />
        {PrivacyData?.map((privacy) => (
          <div key={privacy?.key}>{privacy?.body}</div>
        ))}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
