import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { HomeData, storeLink } from "../../Constant/HomeData";
import "../../App.css";
import "./Carousel.css";
import { NavLink } from "react-router-dom";

function Carousle() {
  return (
    <Carousel
      interval={3000}
      indicators={false}
      controls={true}
      touch={true}
      wrap={true}
      pause={false}
      data-bs-theme="dark"
    >
      {HomeData?.map((item, idx) => (
        <Carousel.Item key={idx}>
          <div className="contain">
            <div
              className="bannerContain"
              style={{ background: `${item?.bgColor}` }}
            >
              <img src={item?.bannerUrl} className="imgP" alt={item?.alt} />
            </div>
            <div className="store">
              {storeLink?.map((btn, storeIndex) => (
                <NavLink to={btn?.link} target="_blank" key={storeIndex}>
                  <div>
                    <img
                      src={`${btn?.image}`}
                      alt={btn?.alt}
                      className={`store1 ${storeIndex === 0 && "playStore"}`}
                    />
                  </div>
                </NavLink>
              ))}
            </div>
            <div className="messageWrapper">
              <img src={item?.message} className="message" alt={item?.alt} />
            </div>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default Carousle;
