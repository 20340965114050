import React, { useEffect, useState } from "react";
import style from './message.module.css'
import userLogo from "../../../assets/app/userlogo.png";
import messageLogo from '../../../assets/app/message.png';
import appStoreLogo from '../../../assets/app/appStore.png';
import playStoreLogo from '../../../assets/app/playStore.png';

import { useLocation } from "react-router-dom";
import axios from "axios";
import { ServerURL } from "../../ServerURL";

const MessagePage = (props) => {
    const [chatData, setChatData] = useState()
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();
    useEffect(() => {
        saveConact()
    }, [])
    const saveConact = async () => {
        const res = await axios.get(`${ServerURL.getChat}${query.get('chatID')}/${query.get('userID')}`)
        if (res?.data?.status === 200) {
            if (res?.data?.data.length > 0) {
                const setData = res?.data?.data[0]
                setChatData(setData)
            }
        }
    };


    return (<>
        <div className={style.container}>
            <img className={style.userLogo} src={chatData?.source_profile_image_url !== "" ? chatData?.source_profile_image_url : ServerURL.noProfilePic} alt="User Logo" />
            <span className={style.headingText}>{`Your ${chatData?.reply_relation} ${chatData?.source_name} has sent you a StrinZ`}</span>

            {
                chatData?.type === "emoji" ?
                    <span className={style.headingText}>{chatData?.message}</span>
                    :
                    <img className={chatData?.type === "sticker" ? style.messageStickerLogo : style.messageLogo} src={chatData?.message} alt="Message Image" />

            }

            <span className={style.messageText}>Install the <b>StrinZ App</b> and share your heartfelt emotions with your loved ones.</span>
            <div>
                <img className={style.playStoreLogo} src={appStoreLogo} alt="App Store Icon" onClick={() => {
                    window.open("https://apps.apple.com/us/app/strinz/id6472786523", '_blank', 'noopener,noreferrer');
                }} />
                <img className={style.playStoreLogo} src={playStoreLogo} alt="App Store Icon" onClick={() => {
                    window.open("https://play.google.com/store/apps/details?id=anova.app.strinz&pli=1", '_blank', 'noopener,noreferrer');
                }} />
            </div>

        </div>
    </>)
}
export default MessagePage;
