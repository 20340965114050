import React, { useState } from "react";

import PolicyModal from "./PolicyModal";
import Button from "../utils/Button";
import CustomQuill from "../utils/CustomEditor";

const EditFAQ = ({ faqs, onUpdate }) => {
  const [faqData, setFaqData] = useState({
    id: null,
    question: "",
    answer: "",
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState("");

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setFaqData({
      id: null,
      question: "",
      answer: "",
    });
    setSelectedQuestion("");
    setModalIsOpen(false);
    setIsEditing(false);
  };

  const handleSelectChange = (event) => {
    const selectedFaq = faqs.find((faq) => faq.question === event.target.value);
    if (selectedFaq) {
      setFaqData({
        id: selectedFaq.faq_id,
        question: selectedFaq.question,
        answer: selectedFaq.answer,
      });
      setSelectedQuestion(selectedFaq.question);
      setIsEditing(true);
      setModalIsOpen(true);
    }
  };

  const handleUpdate = async () => {
    if (faqData.question && faqData.answer) {
      await onUpdate(faqData);
      handleCloseModal();
    }
  };

  return (
    <>
      <Button onClick={handleOpenModal} className="download">
        Edit FAQ
      </Button>
      <PolicyModal isOpen={modalIsOpen} onRequestClose={handleCloseModal}>
        <h2>Edit FAQ</h2>
        <div>
          <label>Select Question:</label>
          <select value={selectedQuestion} onChange={handleSelectChange}>
            <option value="" disabled>
              Select a question to edit
            </option>
            {faqs.map((faq) => (
              <option key={faq.faq_id} value={faq.question}>
                <span dangerouslySetInnerHTML={{ __html: faq.question }} />
              </option>
            ))}
          </select>
        </div>
        {isEditing && (
          <>
            <div>
              <label>Question:</label>
              <CustomQuill
                value={faqData.question}
                onChange={(value) =>
                  setFaqData({ ...faqData, question: value })
                }
              />
            </div>
            <div>
              <label>Answer:</label>
              <CustomQuill
                value={faqData.answer}
                onChange={(value) => setFaqData({ ...faqData, answer: value })}
              />
            </div>
            <div className="bton-container">
              <Button onClick={handleUpdate} className="download">
                Update
              </Button>
              <Button onClick={handleCloseModal} className="download">
                Cancel
              </Button>
            </div>
          </>
        )}
      </PolicyModal>
    </>
  );
};

export default EditFAQ;
