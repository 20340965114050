import React, { useState } from "react";

import "react-quill/dist/quill.snow.css";
import PolicyModal from "./PolicyModal";
import Button from "../utils/Button";
import CustomQuill from "../utils/CustomEditor";
import { updateTermsAndConditionsApi } from "./api/termsApi";
import TransactionPasswordModal from "../utils/TransModal";
const AddTermsAndConditions = ({ token, onUpdate, value, setValue }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [saving, setSaving] = useState(false); // State for saving indicator
  const [error, setError] = useState(null); // State for error handling
  const [isTransPassModalOpen, setIsTransPassModalOpen] = useState(false);
  const [loadingTransPass, setLoadingTransPass] = useState(false);

  const handleChange = (content) => {
    setValue(content);
  };

  const handleOpenModal = () => {
    setModalIsOpen(true);
    setError(null); // Reset error when opening the modal
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setValue("");
    onUpdate();
  };

  const handleTransPassSubmit = async (transPassword) => {
    setLoadingTransPass(true);
    setError("");
    try {
      const response = await updateTermsAndConditionsApi(
        token,
        value,
        transPassword
      );

      if (response && response.success) {
        alert("Terms updated successfully.");
        onUpdate();
        handleCloseModal();
        setIsTransPassModalOpen(false);
      } else if (response && response.error) {
        setError(response.error);
        alert(response.error);
      } else {
        setError("An unexpected error occurred.");
        alert("An unexpected error occurred.");
      }
    } catch (error) {
      setError("Failed to update terms and conditions. Please try again.");
      alert("Failed to update terms and conditions. Please try again.");
    } finally {
      setLoadingTransPass(false);
      handleCloseModal();
    }
  };

  const handleSave = () => {
    if (!value.trim()) {
      setError("Content cannot be empty.");
      return;
    }
    setIsTransPassModalOpen(true);
  };

  return (
    <>
      <button onClick={handleOpenModal} className="download">
        Add Terms
      </button>
      <PolicyModal isOpen={modalIsOpen} onRequestClose={handleCloseModal}>
        <h2>Add Terms and Conditions</h2>

        <CustomQuill value={value} onChange={handleChange} />
        <div className="bton-container">
          <Button onClick={handleSave} className="download" disabled={saving}>
            {saving ? "Saving..." : "Save"}
          </Button>
          <Button onClick={handleCloseModal} className="download">
            Cancel
          </Button>
        </div>
        <TransactionPasswordModal
          isOpen={isTransPassModalOpen}
          onRequestClose={() => setIsTransPassModalOpen(false)}
          onSubmit={handleTransPassSubmit}
          loading={loadingTransPass}
          setIsTransPassModalOpen={setIsTransPassModalOpen}
        />
      </PolicyModal>
    </>
  );
};

export default AddTermsAndConditions;
