import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useToken } from "../../utils/useToken";
import {
  getStickerTypes as apiGetStickerTypes,
  getStickersByType as apiGetStickersByType,
  uploadSticker as apiUploadSticker,
  deleteStickers as apiDeleteStickers,
} from "./StickiesApi";
import { validateFile } from "./Validate";

export const useStickersAPI = () => {
  const { token } = useToken();
  const [types, setTypes] = useState([]);
  const [stickers, setStickers] = useState([]);
  const [loadingTypes, setLoadingTypes] = useState(false);
  const [loadingStickers, setLoadingStickers] = useState(false);
  const [activeTab, setActiveTab] = useState("Birthday");

  const getStickerTypes = async () => {
    try {
      setLoadingTypes(true);
      const fetchedTypes = await apiGetStickerTypes(token);
      setTypes(fetchedTypes);
    } catch (error) {
      console.error("Error fetching sticker types:", error);
      toast.error("Failed to fetch sticker types.");
    } finally {
      setLoadingTypes(false);
    }
  };

  const getStickersByType = async (tab) => {
    try {
      setLoadingStickers(true);
      const fetchedStickers = await apiGetStickersByType(token, tab);
      setStickers(fetchedStickers);
    } catch (error) {
      console.error("Error fetching stickers:", error);
      toast.error("Failed to fetch stickers.");
    } finally {
      setLoadingStickers(false);
    }
  };

  const uploadSticker = async (stickyType, file, transPassword) => {
    if (!file || !stickyType) {
      toast.error("Please provide all the fields.");
      return;
    }
    try {
      await validateFile(file);
    } catch (validationError) {
      toast.error(validationError);
      return;
    }

    try {
      setLoadingStickers(true);
      const response = await apiUploadSticker(
        token,
        stickyType,
        file,
        transPassword
      );

      if (response.status === 200) {
        toast.success("Sticker uploaded successfully!");
        getStickersByType(activeTab); // Fetch updated stickers
      } else {
        toast.error(response.data);
        setLoadingStickers(false);
        return false;
      }
    } catch (error) {
      console.error("Error uploading sticker:", error);
      toast.error(`Incorrect transaction password`);
      setLoadingStickers(false);
      return false;
    }
  };

  const deleteStickers = async (fileUrls, transPassword) => {
    try {
      const response = await apiDeleteStickers(token, fileUrls, transPassword);
      if (response.status === 201) {
        toast.success("Stickers deleted successfully!");
        return true;
      } else {
        toast.error("Failed to delete stickers.");
        return false;
      }
    } catch (error) {
      console.error("Error deleting stickers:", error);
      toast.error(`Delete failed: ${error.message}`);
      return false;
    }
  };

  useEffect(() => {
    if (token) {
      getStickerTypes();
      getStickersByType(activeTab);
    }
  }, [token, activeTab]);

  return {
    types,
    stickers,
    loadingTypes,
    loadingStickers,
    getStickerTypes,
    getStickersByType,
    uploadSticker,
    deleteStickers,
    activeTab,
    setActiveTab,
  };
};
