import StickerCard from "./StickerCard";
import LoadingSpinner from "../utils/Spinner";
import "./filter.css";
const StickerList = ({
  stickers,
  loading,
  selectedStickers,

  onCheckboxChange,
}) => {
  return (
    <div className="sticker-list">
      {loading ? (
        <div>
          <LoadingSpinner />
        </div>
      ) : (
        stickers?.map((sticker, index) => (
          <div key={index} className="sticker-item">
            <input
              type="checkbox"
              checked={selectedStickers.includes(sticker)}
              onChange={() => onCheckboxChange(sticker)}
              className="sticker-checkbox"
            />
            <StickerCard sticker={sticker} />
          </div>
        ))
      )}
    </div>
  );
};

export default StickerList;
