import React from "react";
import Button from "../utils/Button";

const AddSingleMessageButton = ({
  setIsAddSingleModalOpen,
  selectedRelation,
  selectedEmotion,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "10px",
      }}
    >
      <Button
       
        onClick={() => setIsAddSingleModalOpen(true)}
        disabled={!selectedRelation || !selectedEmotion}
      >
        Add Message
      </Button>
    </div>
  );
};

export default AddSingleMessageButton;
