import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";

import App from "./App";

const root = document.getElementById("root");
ReactDOM.createRoot(root).render(<App />);

reportWebVitals();
