import React from "react";

import withSideBarLayout from "../../sidebarlayout/withSideBarLayout";
import Background from "../../bacground/Background";

const BackgroundPage = () => {
  return <Background />;
};

export default withSideBarLayout(BackgroundPage);
