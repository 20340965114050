// ModalComponent.js
import React from "react";
import Modal from "react-modal";

const PolicyModal = ({ isOpen, onRequestClose, children }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    // style={customStyles}
    contentLabel="Modal"
  >
    {children}
  </Modal>
);

export default PolicyModal;
