import { useState, useEffect } from "react";
import axios from "axios";
import { BASE_API_URL } from "../../config/apiURL/Base_Api";
import { useToken } from "../../utils/useToken";

const useFAQ = () => {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token } = useToken();

  useEffect(() => {
    if (token) {
      fetchFAQs();
    }
  }, [token]);

  const fetchFAQs = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_API_URL}/getFAQadmin`, {
        headers: { Auth: token },
      });

      if (response.data && response.data.data) {
        const filteredFaqs = response.data.data.filter(
          (faq) => faq.question.trim() !== "" && faq.answer.trim() !== ""
        );
        setFaqs(filteredFaqs);
      } else {
        alert("No FAQs found.");
      }
    } catch (error) {
      console.error("Error fetching FAQs:", error);
      alert("Failed to fetch FAQs. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const addFAQ = async (data, transPass) => {
    const { question, answer } = data;
    try {
      const response = await axios.post(
        `${BASE_API_URL}/addFAQadmin`,
        { question, answer, transPass },
        { headers: { Auth: token } }
      );

      if (response.data.status === 200) {
        fetchFAQs();
        alert("FAQ added successfully!");
      } else {
        alert("Failed to add FAQ. Please try again.");
      }
    } catch (error) {
      console.log("Error adding FAQ:", error);
      alert("Failed to add FAQ. Please try again.");
    }
  };

  const updateFAQ = async (data, transPass) => {
    const { id, question, answer } = data;
    try {
      const response = await axios.post(
        `${BASE_API_URL}/updateFAQadmin`,
        { faq_id: id, question, answer, transPass },
        { headers: { Auth: token } }
      );

      if (response.data.status === 200) {
        fetchFAQs();
        alert("FAQ updated successfully!");
      } else {
        alert("Failed to update FAQ. Please try again.");
      }
    } catch (error) {
      console.error("Error updating FAQ:", error);
      alert("Failed to update FAQ. Please try again.");
    }
  };

  const deleteFAQ = async (faq_id, transPass) => {
    const confirm = window.confirm("Do you want to delete the FAQ?");
    if (!confirm) {
      return;
    }
    try {
      const response = await axios.delete(
        `${BASE_API_URL}/deleteFAQadmin/${faq_id}`,
        { headers: { Auth: token }, data: { transPass } }
      );

      if (response.data.status === 200) {
        fetchFAQs();
        alert("FAQ deleted successfully!");
      } else {
        alert("Failed to delete FAQ. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting FAQ:", error);
      alert("Failed to delete FAQ. Please try again.");
    }
  };

  return { faqs, loading, addFAQ, updateFAQ, deleteFAQ };
};

export default useFAQ;
