// RelationshipDataGrid.js
import React from "react";
import { DataGrid } from "@mui/x-data-grid";

const RelationshipDataGrid = ({
  relationships = [],
  selectedId,
  handleRadioChange,
  handleEdit,
  handleDelete,
  deletingId,
  permission,
}) => {
  // Define allowed fields for editing
  const allowedFields = ["Id", "Male", "Female", "Other"]; // Removed "Id" from allowed fields

  const columns = [
    {
      field: "select",
      headerName: "Select",
      renderCell: (params) => (
        <input
          type="radio"
          checked={selectedId === params.row.Id}
          onChange={() => handleRadioChange(params.row.Id)}
          disabled={deletingId === params.row.Id}
          style={{ cursor: "pointer" }}
        />
      ),
      sortable: false,
      width: 100,
    },
    { field: "Name", headerName: "Name", width: 150 },
    { field: "Male", headerName: "Male", width: 200 },
    { field: "Female", headerName: "Female", width: 200 },
    { field: "Other", headerName: "Other", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => {
        const isSelected = selectedId === params.row.Id;
        const isDeleting = deletingId === params.row.Id;
        const canEdit = isSelected && permission === "write";

        return (
          <div className="cellAction">
            {isDeleting ? (
              <span className="deleteButton">Deleting...</span>
            ) : (
              canEdit && (
                <>
                  <span
                    className="viewButton"
                    onClick={() => handleEdit(filterAllowedFields(params.row))}
                  >
                    Edit
                  </span>
                  <span
                    className="deleteButton"
                    onClick={() => handleDelete(params.row.Id)}
                  >
                    Delete
                  </span>
                </>
              )
            )}
          </div>
        );
      },
      sortable: false,
      width: 200,
    },
  ];

  // Helper function to filter allowed fields for editing
  const filterAllowedFields = (row) => {
    const filteredRow = {};
    allowedFields.forEach((field) => {
      if (row.hasOwnProperty(field)) {
        filteredRow[field] = row[field];
      }
    });
    return filteredRow;
  };

  return (
    <div className="datatable1">
      <DataGrid
        className="datagrid"
        rows={relationships}
        columns={columns}
        getRowId={(row) => row.Id}
        pagination={false}
        hideFooter
        autoHeight
        style={{ paddingBottom: "3%" }}
      />
    </div>
  );
};

export default RelationshipDataGrid;
