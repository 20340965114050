import React from "react";
import { AboutUsData } from "../DATA/AboutUsData";
import "../Common/Common.css";
import CommonHeader from "../Common/CommonHeade";
import { useScrollToTop } from "../useScrollToTop";
const About = () => {
  useScrollToTop();
  return (
    <>
      <div className="wrapper">
        <div className="wrap">
          <CommonHeader children={"About Us!"} />
          {AboutUsData?.map((aboutD, index) => (
            <div key={index}>
              {aboutD.Heading && <h3>{aboutD.Heading}</h3>}
              {aboutD.Points && (
                <p>
                  <ul className="listItem">
                    {aboutD.Points.map((point, i) => (
                      <li key={i}>{point}</li>
                    ))}
                  </ul>
                </p>
              )}
              {aboutD.Para1 && <p>{aboutD.Para1}</p>}
              <p>{aboutD.Para}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default About;
