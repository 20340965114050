import React from "react";
import { Button } from "react-bootstrap";

const Downbtn = () => {
  return (
    <div className="read-btn">
      <Button
        type="button"
        className="read"
        data-bs-toggle="modal"
        data-bs-target="#qr"
      >
        Download App
      </Button>
    </div>
  );
};

export default Downbtn;
