import React from "react";
import "./datatable.css";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns } from "../datatablesource";
import LoadingSpinner from "../utils/Spinner";
import useUserManagement from "./useUsermanagment";
import { useNavigate, useLocation } from "react-router-dom";
import { useRole } from "../utils/useToken";
const Datatable = () => {
  const { data, loading, error, deleteUser, navigateToUser } =
    useUserManagement();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useRole();

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 250,
      renderCell: (params) => {
        return (
          user === "superAdmin" && (
            <div className="cellAction">
              <div
                className="viewButton"
                onClick={() => navigateToUser(params.row.id)}
              >
                View
              </div>
              <div
                className="deleteButton"
                onClick={() => deleteUser(params.row.id)}
              >
                Delete
              </div>
            </div>
          )
        );
      },
    },
  ];

  if (loading) {
    return (
      <p>
        <LoadingSpinner />
      </p>
    );
  }

  if (error) {
    return <p>Error fetching data: {error.message}</p>;
  }

  return (
    <div className="datatable">
      <div className="datatableTitle">
        {location.pathname == "/admin/user/home" ? "Users" : "Existing User"}
        {location.pathname !== "/admin/user/home" && user === "superAdmin" && (
          <div className="download" onClick={() => navigate("/admin/user/new")}>
            Add New
          </div>
        )}
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={userColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
      />
    </div>
  );
};

export default Datatable;
