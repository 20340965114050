import LoginForm from "../../authentication/Login";
import Cover from "./Cover";
import "./login.css";

const Login = () => {
  return (
    <div className="login-container">
      <div className="login-inner">
        <Cover />
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
