import React, { useState } from "react";

import PolicyModal from "./PolicyModal";

import "../messageList/styling/RelationshipData.css";
import Button from "../utils/Button";

const DeleteFAQ = ({ faqs, onDelete }) => {
  const [selectedFaq, setSelectedFaq] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedFaq(null);
    setModalIsOpen(false);
  };

  const handleSelectChange = (event) => {
    const selectedQuestion = event.target.value;
    const faq = faqs.find((faq) => faq.question === selectedQuestion);
    setSelectedFaq(faq);
  };
  const handleDelete = async () => {
    if (selectedFaq) {
      await onDelete(selectedFaq.faq_id);
      handleCloseModal();
    }
  };

  return (
    <>
      <Button onClick={handleOpenModal} className="download">
        Delete FAQ
      </Button>
      <PolicyModal isOpen={modalIsOpen} onRequestClose={handleCloseModal}>
        <div
          className="bton-container"
          style={{ justifyContent: "space-between" }}
        >
          <h2>Delete FAQ</h2>
          <Button onClick={handleCloseModal} className="download">
            Cancel
          </Button>
        </div>
        <div
          style={{
            display: "flex",

            width: "100%",
            flexDirection: "column",
          }}
        >
          <label>Select Question:</label>
          <select
            value={selectedFaq ? selectedFaq.question : ""}
            onChange={handleSelectChange}
            style={{ padding: "1%", borderRadius: "5px" }}
          >
            <option value="">Select a question...</option>
            {faqs.map((faq) => (
              <option key={faq.id} value={faq.question}>
                {/* Render HTML content */}
                <span dangerouslySetInnerHTML={{ __html: faq.question }} />
              </option>
            ))}
          </select>
        </div>
        {selectedFaq && (
          <div className="bton-container">
            <Button onClick={handleDelete} className="download">
              Delete
            </Button>
          </div>
        )}
      </PolicyModal>
    </>
  );
};

export default DeleteFAQ;
