import React from "react";

import Widget from "../../widget/Widget";
import Datatable from "../../datatable/Datatable";

import "./home.css";
import withSideBarLayout from "../../sidebarlayout/withSideBarLayout";

const AdminHome = () => {
  return (
    <div className="home">
      <div className="homeContainer">
        <div className="widgets">
          <Widget type="userReport" />
          <Widget type="emotion" />
          <Widget type="sticky" />
          <Widget type="relation" />
        </div>
        <div>
          <Datatable />
        </div>
      </div>
    </div>
  );
};

export default withSideBarLayout(AdminHome);
