import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { BASE_API_URL } from "../../config/apiURL/Base_Api";
import { useToken } from "../../utils/useToken";
import { handle500Error } from "../../config/logout500/Handle500";

const useRelationshipMessages = () => {
  const [relationships, setRelationships] = useState([]);
  const [selectedRelation, setSelectedRelation] = useState(
    sessionStorage.getItem("selectedRelation") || ""
  );
  const [selectedEmotion, setSelectedEmotion] = useState(
    sessionStorage.getItem("selectedEmotion") || ""
  );
  const [allMessages, setAllMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [error, setError] = useState(null);
  const [editingMessage, setEditingMessage] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddSingleModalOpen, setIsAddSingleModalOpen] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [page, setPage] = useState(sessionStorage.getItem("page") || 1);
  const [count, setCount] = useState(0);
  const { token } = useToken();
  const memoizedRelationships = useMemo(() => relationships, [relationships]);
  const memoizedEmotions = useMemo(() => {
    if (selectedRelation) {
      const relation = relationships.find(
        (relation) => relation.RelationId === parseInt(selectedRelation)
      );
      if (relation) {
        return relation.Libraries.map((library) => ({
          value: library.Emotion,
          label: library.Emotion,
        }));
      }
    }
    return [];
  }, [relationships, selectedRelation]);

  useEffect(() => {
    if (token) {
      fetchRelationships();
    }
  }, [token]);

  useEffect(() => {
    if (selectedRelation && selectedEmotion) {
      fetchMessages(selectedRelation, selectedEmotion);
    }
  }, [selectedRelation, selectedEmotion, page, token]);

  const fetchRelationships = async () => {
    setLoadingMessages(true);
    try {
      const response = await axios.get(
        `${BASE_API_URL}/getAdminRelationships`,
        {
          headers: {
            "Content-Type": "application/json",
            auth: token,
          },
        }
      );

      // if (response.data.status === 401) {
      //   handle500Error();
      //   return;
      // }
      if (response.data.status === 200) {
        setRelationships(response.data.data);
      } else {
        setError(new Error("Unexpected response format"));
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoadingMessages(false);
    }
  };

  const handleRelationChange = (event) => {
    const selectedRelationId = event.target.value;
    setSelectedRelation(selectedRelationId);
    sessionStorage.setItem("selectedRelation", selectedRelationId);
    setSelectedEmotion("");
    setAllMessages([]);
    setPage(1);
  };

  const handleEmotionChange = (event) => {
    const selectedEmotion = event.target.value;
    setSelectedEmotion(selectedEmotion);
    sessionStorage.setItem("selectedEmotion", selectedEmotion);
    setAllMessages([]);
    setPage(1);
    fetchMessages(selectedRelation, selectedEmotion);
  };

  const fetchMessages = async (
    relationId = selectedRelation,
    emotion = selectedEmotion
  ) => {
    setLoadingMessages(true);
    try {
      const response = await axios.get(
        `${BASE_API_URL}/Relationship/${emotion}/Library/${relationId}?page=${page}&pageSize=50`,
        {
          headers: {
            "Content-Type": "application/json",
            auth: token,
          },
        }
      );

      // if (response.data.status === 401) {
      //   handle500Error();
      //   return;
      // }
      if (response.data.status === 200) {
        const newMessages = response.data.data.data;
        setAllMessages(newMessages);
        setCount(response.data.data.count);
      }
    } catch (error) {
      console.log(error.response.data.message);
      alert(error.response.data.message);
    } finally {
      setLoadingMessages(false);
    }
  };

  const handleEditMessage = (message) => {
    setEditingMessage(message);
    setIsEditModalOpen(true);
  };

  const handleUpdateMessage = async (updatedMessage, transPass) => {
    if (!updatedMessage) {
      setError(new Error("Message field empty."));
      return;
    }
    try {
      const response = await axios.post(
        `${BASE_API_URL}/updateMessage`,
        {
          id: updatedMessage.library_id,
          relationshipId: selectedRelation,
          emotion: selectedEmotion,
          message: updatedMessage.message,
          transPass: transPass,
        },
        {
          headers: {
            "Content-Type": "application/json",
            auth: token,
          },
        }
      );
      // if (response.data.status === 401) {
      //   handle500Error();
      //   return;
      // }

      if (response.data.status === 200) {
        alert(response.data.message);
        setAllMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.library_id === updatedMessage.library_id ? updatedMessage : msg
          )
        );

        setEditingMessage(null);
        setIsEditModalOpen(false);
      }
    } catch (error) {
      console.log(error.response.data.message);
      alert(error.response.data.message);
    } finally {
      setLoadingMessages(false);
    }
  };

  const handleDeleteMessage = async (messageId, transPassword) => {
    setDeletingId(messageId);
    const confirm = window.confirm("Do you want to delete the message?");
    if (!confirm) {
      setDeletingId(null);
      return;
    }
    try {
      const response = await axios.delete(
        `${BASE_API_URL}/message/delete/${messageId}`,
        {
          headers: {
            "Content-Type": "application/json",
            auth: token,
          },
          data: { transPass: transPassword },
        }
      );
      // if (response.data.status === 401) {
      //   handle500Error();
      //   return;
      // }
      if (response.data.status === 200) {
        alert(response.data.message);
        setAllMessages((prevMessages) =>
          prevMessages.filter((msg) => msg.library_id !== messageId)
        );

        setDeletingId(null);
      }
    } catch (error) {
      console.log(error.response.data.message);
      alert(error.response.data.message);
    } finally {
      setLoadingMessages(false);
    }
  };

  const handleAddMessages = async (file, transPass) => {
    if (!file) {
      setError(new Error("No file selected"));
      return;
    }

    try {
      const formData = new FormData();

      formData.append("file", file);
      formData.append("transPass", transPass);
      const response = await axios.post(
        `${BASE_API_URL}/addMessageViaCSV`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            auth: token,
          },
        }
      );
      // if (response.data.status === 401) {
      //   handle500Error();
      //   return;
      // }
      if (response.data.status === 200) {
        alert(response.data.message);

        if (response.data.data.failureCount > 0) {
          const failureMessages = response.data.data.failureMessages;
          alert(`Failed to add messages:\n${failureMessages.join("\n")}`);
          return;
        }

        if (response.data.data.alreadyExistsCount > 0) {
          alert(`Some messages already exist`);
          return;
        }

        // No need to fetch messages here
      }
    } catch (error) {
      console.log(error.response.data.message);
      alert(error.response.data.message);
    }
  };

  const handleAddSingleMessage = async (addMessage, transPass) => {
    const { relation, emotion, message } = addMessage;
    try {
      const response = await axios.post(
        `${BASE_API_URL}/addNewMessage`,
        {
          relationshipId: relation,
          emotion: emotion,
          message: message,
          transPass: transPass,
        },
        {
          headers: {
            "Content-Type": "application/json",
            auth: token,
          },
        }
      );

      // if (response.data.status === 401) {
      //   handle500Error();
      //   return;
      // }
      if (response.data.status === 200) {
        alert(response.data.message);
        fetchMessages(relation, emotion);
        // No need to fetch messages here
      }
    } catch (error) {
      console.log(error.response.data.message);
      alert(error.response.data.message);
    }
  };

  return {
    relationships: memoizedRelationships,
    emotions: memoizedEmotions,
    selectedRelation,
    selectedEmotion,
    allMessages,
    loadingMessages,
    deletingId,
    error,
    editingMessage,
    isEditModalOpen,
    selectedMessages,
    page,
    count,
    handleRelationChange,
    handleEmotionChange,
    handleEditMessage,
    handleUpdateMessage,
    handleDeleteMessage,
    handleAddMessages,
    handleAddSingleMessage,
    setEditingMessage,
    setSelectedMessages,
    setIsEditModalOpen,
    isAddModalOpen,
    setPage,
    setError,
    setIsAddSingleModalOpen,
    isAddSingleModalOpen,
    setIsAddModalOpen,
  };
};

export default useRelationshipMessages;
