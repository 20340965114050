import React from "react";

import withSideBarLayout from "../../sidebarlayout/withSideBarLayout";
import "./pass.css";
import ChangeTransactionPassword from "../../authentication/ChangeTransPass";
const TranPass = () => {
  return (
    <div className="pass">
      <ChangeTransactionPassword />
    </div>
  );
};

export default withSideBarLayout(TranPass);
