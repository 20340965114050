import { useState } from "react";
import axios from "axios";
import { BASE_API_URL } from "../config/apiURL/Base_Api";
import { useToken } from "../utils/useToken";
import { handle500Error } from "../config/logout500/Handle500";

const usePasswordChange = (endpoint, fields) => {
  const [formData, setFormData] = useState({
    [fields.password]: "",
    [fields.confirmPassword]: "",
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const { token } = useToken();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if fields are empty
    if (!formData[fields.password] || !formData[fields.confirmPassword]) {
      setError("Please fill out both password fields.");
      return;
    }
    if (formData[fields.password] !== formData[fields.confirmPassword]) {
      setError("Passwords don't match.");
      return;
    }

    setError(null);
    setSuccess(false);
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_API_URL}/${endpoint}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            auth: token,
          },
        }
      );
      if (response.data.status === 400) {
        alert(response.data.data);
        return;
      }
      if (response.data && response.data.status === 201) {
        setSuccess(true);
        alert("Password changed successfully!");
        setFormData({
          [fields.password]: "",
          [fields.confirmPassword]: "",
        });

        if (endpoint === "changeSelfPassword") {
          handle500Error();
          return;
        }
      }
    } catch (error) {
      console.error("Error changing password:", error);
      alert(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    formData,
    error,
    success,
    loading,
    handleChange,
    handleSubmit,
  };
};

export default usePasswordChange;
