import React from "react";
import modalimage from "../../assets/QR.png";
import app from "../../assets/AppStore (1).png";
import play from "../../assets/playStore (1).png";
import "./Qr.css";
import { Link } from "react-router-dom";

function QrModal() {
  return (
    <>
      <div
        className="modal fade"
        id="qr"
        tabIndex={-1}
        aria-labelledby="qrLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header custom-header">
              <button
                type="button"
                className="custom-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                &times;
              </button>
            </div>
            <div className="modal-body custom-body">
              <div className="row">
                <div className="col-md-8 custom-text">
                  <h2>
                    Scan this QR code to
                    <br /> Download StrinZ app
                  </h2>
                  <ul>
                    <li>
                      Unlock your phone and launch your camera app, then focus
                      it on the QR code.
                    </li>
                    <li>
                      Alternatively, you can download a QR code scanner from
                      your app store.
                    </li>
                    <li>
                      Once scanned, tap the generated link to start downloading
                      StrinZ.
                    </li>
                    <div className="modal-footer custom-footer">
                      <Link
                        to="https://apps.apple.com/in/app/strinz/id6472786523"
                        target="_blank"
                      >
                        <img src={app} className="app" alt="App Store" />
                      </Link>
                      <Link
                        to="https://play.google.com/store/search?q=strinz&c=apps&hl=en_IN&gl=US"
                        target="_blank"
                      >
                        <img src={play} className="app" alt="Play Store" />
                      </Link>
                    </div>
                  </ul>
                </div>
                <div className="col-md-4 custom-image">
                  <img
                    src={modalimage}
                    className="card-img-top"
                    alt="QR Code"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default QrModal;
