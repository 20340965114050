export const validateFile = (file) => {
  const fileTypes = ["image/png"];
  const dimensions = { width: 270, height: 170 };

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      if (img.width === dimensions.width && img.height === dimensions.height) {
        if (!fileTypes.includes(file.type)) {
          reject("File type must be PNG.");
        } else {
          resolve();
        }
      } else {
        reject("Image dimensions must be 270px X 170px.");
      }
    };
    img.onerror = () => reject("Invalid image file.");
    img.src = URL.createObjectURL(file);
  });
};
