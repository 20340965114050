import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Carousel from "./Carousel";
import HowItWork from "./HowItWork";
import Customize from "./Customize";
import HomeFaq from "./HomeFaq";
import { useScrollToTop } from "../useScrollToTop";
export default function Home() {
  useScrollToTop();
  return (
    <>
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <Carousel />
      </div>
      <HowItWork />
      <Customize />
      <HomeFaq />
    </>
  );
}
