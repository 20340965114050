// utils/Modal.js
import React from "react";
import Modal from "react-modal";
import { customModalStyles } from "./customModalStyles";

const CustomModal = ({ isOpen, onRequestClose, title, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={title}
      style={customModalStyles}
    >
      <h2>{title}</h2>
      {children}
    </Modal>
  );
};

export default CustomModal;
