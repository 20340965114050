import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../config/apiURL/Base_Api";
import { handle500Error } from "../config/logout500/Handle500";
import { useNavigate } from "react-router-dom";

const useBackgroundData = (token) => {
  const [backGround, setBackGround] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchBack();
  }, [token]);

  const fetchBack = async () => {
    if (!token) return;
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_API_URL}/adminBackGroundSticker`,
        {
          headers: {
            auth: token,
          },
        }
      );

      if (response.data.status === 401) {
        handle500Error(navigate);
        return;
      }
      if (response.data.status === 200) {
        setLoading(false);
        setBackGround(response.data.data.data);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching background ", error);
    }
  };

  const deleteBackground = async (selectedBack, transPassword) => {
    try {
      setLoading(true);
      const response = await axios.delete(
        `${BASE_API_URL}/adminDeleteSticker`,
        {
          headers: {
            "Content-Type": "application/json",
            auth: token,
          },
          data: { fileUrls: selectedBack, transPass: transPassword },
        }
      );

      if (response.data.status === 401) {
        handle500Error();
        return;
      }
      if (response.data.status === 201) {
        toast.success("Background deleted successfully!");
        fetchBack();
      } else {
        toast.error("Failed to delete background.");
      }
    } catch (error) {
      console.error("Error deleting background:", error);
      toast.error(
        `Delete failed: ${error.response?.data?.message || error.message}`
      );
    } finally {
      setLoading(false);
    }
  };

  const uploadBackground = async (file, transPassword) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("transPass", transPassword);

    try {
      setLoading(true);
      const response = await axios.post(
        `${BASE_API_URL}/adminUploadBackGroundImage`,
        formData,

        {
          headers: {
            "Content-Type": "multipart/form-data",
            auth: token,
          },
        }
      );
      if (response && response.status === 401) {
        handle500Error();
        return;
      }
      if (response.data.status === 200) {
        toast.success("Background uploaded successfully!");
        fetchBack();
      } else {
        toast.error(response.data.data);
      }
    } catch (uploadError) {
      console.error("Error uploading background:", uploadError);
      toast.error(
        `Upload failed: ${
          uploadError.response?.data?.message || uploadError.message
        }`
      );
    } finally {
      setLoading(false);
    }
  };

  return {
    backGround,
    loading,
    error,
    fetchBack,
    deleteBackground,
    uploadBackground,
  };
};

export default useBackgroundData;
