import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import useWidgetData from "./useWidget";
import "./widget.css"; // Import your CSS file

const Widget = ({ type }) => {
  const [selectedRange, setSelectedRange] = useState("last24hours");
  const { data, loading } = useWidgetData(type, selectedRange);

  const handleChange = (event) => {
    setSelectedRange(event.target.value);
  };

  const renderTimeRangeDropdown = () => {
    return (
      <div className="dropdown">
        <select
          className="form-control"
          id="timeRangeSelect"
          value={selectedRange}
          onChange={handleChange}
          style={{ width: "90px", fontSize: "14px" }}
        >
          <option value="last24hours"> 24 Hours</option>
          <option value="last7days"> 7 Days</option>
          <option value="tilldate">Till Date</option>
        </select>
      </div>
    );
  };

  const renderDetailsList = () => {
    if (!data.details) return null;

    // Split details string into key-value pairs
    const detailsArray = data.details.split(",").map((item) => item.trim());

    return (
      <ul className="details-list">
        {detailsArray?.map((detail, index) => (
          <li key={index}>{detail}</li>
        ))}
      </ul>
    );
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="form-group">
          <div className="timeRange">
            <h5 className="card-title">{data.title}</h5>
          </div>
          {renderTimeRangeDropdown()} {/* Render the dropdown */}
        </div>
        {renderDetailsList()} {/* Render the details list */}
        <div>
          {/* Conditionally render based on the type */}
          {type === "userReport" ? null : (
            <p className="card-text-count">
              <strong>
                {type === "emotion"
                  ? `Total Strinz: ${data.count ? data.count : 0}`
                  : type === "sticky"
                  ? `Total Stickies: ${data.count ? data.count : 0}`
                  : null}
              </strong>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Widget;
