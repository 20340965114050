// src/hooks/useRelationships.js

import { useEffect, useState, useCallback } from "react";
import { useToken, usePermission } from "../../utils/useToken";
import {
  fetchRelationships,
  addRelationship,
  deleteRelationship,
  updateRelationships,
} from "../api/relationApi";
import { useModal } from "./useModal";

const useRelationships = () => {
  const [relationships, setRelationships] = useState([]);
  const [error, setError] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [operation, setOperation] = useState(""); // new state to track the current operation
  const [deletingId, setDeletingId] = useState(null);
  const [editingRelationship, setEditingRelationship] = useState(null);
  const { isOpen: isModalOpen, openModal, closeModal } = useModal();
  const {
    isOpen: isEditModalOpen,
    openModal: openEditModal,
    closeModal: closeEditModal,
  } = useModal();
  const { token } = useToken();
  const { permission } = usePermission();

  useEffect(() => {
    const getRelationships = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await fetchRelationships(token);
        setRelationships(data);
      } catch (error) {
        setError(error.message);
        alert(`Error: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      getRelationships();
    }
  }, [token, isModalOpen, isEditModalOpen]);

  const handleRadioChange = (id) => {
    setSelectedId(id);
  };

  const handleAdd = async (newRelationship, transPassword) => {
    setOperation("Adding");
    try {
      const nameExists = relationships.some(
        (relationship) =>
          relationship.Name.toLowerCase() === newRelationship.name.toLowerCase()
      );

      if (nameExists) {
        throw new Error("Name already exists");
      }

      const addedRelationship = await addRelationship(
        newRelationship,
        token,
        transPassword // Pass transaction password to addRelationship
      );
      setRelationships((prevRelationships) => [
        addedRelationship,
        ...prevRelationships,
      ]);
      // alert("Relationship added successfully");
    } catch (error) {
      alert(`Error: ${error.message}`);
    } finally {
      setOperation("");
    }
  };

  const handleDelete = async (id, transPassword) => {
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    if (!confirmDelete) {
      return;
    }

    setDeletingId(id);
    setOperation("Deleting");
    setError(null);

    try {
      const status = await deleteRelationship(id, token, transPassword);

      if (status === 200) {
        // alert("Relationship deleted successfully");
        setRelationships((prevRelationships) =>
          prevRelationships.filter((rel) => rel.Id !== id)
        );
      }
    } catch (error) {
      console.log(error.response?.data?.message || error.message);
      alert(`Error: ${error.response?.data?.message || error.message}`);
      setError(error.message);
    } finally {
      setDeletingId(null);
      setOperation("");
    }
  };

  const handleEdit = (relationship) => {
    setEditingRelationship(relationship);
    openEditModal();
  };

  const handleUpdate = async (updatedRelationship, transPassword) => {

    setOperation("Saving");
    setError(null);
    try {
      const updatedData = await updateRelationships(
        updatedRelationship,
        token,
        transPassword
      );
      setRelationships((prevRelationships) =>
        prevRelationships.map((rel) =>
          rel.Id === updatedData.Id ? { ...updatedData } : rel
        )
      );
      // alert("Relationship updated successfully");
      closeEditModal();
      setEditingRelationship(null);
    } catch (error) {
      alert(`Error: ${error.message}`);
      setError(error.message);
    } finally {
      setOperation("");
    }
  };

  return {
    relationships,
    error,
    selectedId,
    loading,
    token,
    isModalOpen,
    deletingId,
    editingRelationship,
    isEditModalOpen,
    permission,
    handleRadioChange,
    handleAdd,
    openModal,
    closeModal,
    handleEdit,
    setDeletingId,
    handleUpdate,
    setOperation,
    setEditingRelationship,
    setError,
    handleDelete,
    setRelationships,
    deleteRelationship,
  };
};

export default useRelationships;
