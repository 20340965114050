import React, { useState } from "react";
import axios from "axios";
import Modal from "react-modal"; // Import react-modal
import { BASE_API_URL } from "../../config/apiURL/Base_Api";
import { useToken } from "../../utils/useToken";
import Button from "../../utils/Button";
import { customModalStyles } from "../../utils/customModalStyles";
import { handle500Error } from "../../config/logout500/Handle500";
import Instruction from "../../utils/Instruction";

Modal.setAppElement("#root"); // Set the root element for accessibility

const ChangePasswordModal = ({
  isOpen,
  onClose,
  userId,
  title,
  passwordField,
  confirmField,
  endpoint,
  successMessage,
  errorMessage,
}) => {
  const [formData, setFormData] = useState({
    id: userId,
    [passwordField]: "",
    [confirmField]: "",
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const { token } = useToken();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if fields are empty
    if (!formData[passwordField] || !formData[confirmField]) {
      setError("Please fill out both password fields.");
      return;
    }

    // Check if passwords match
    if (formData[passwordField] !== formData[confirmField]) {
      setError("Passwords do not match. Please enter matching passwords.");
      return;
    }

    setError(null);
    setSuccess(false);

    try {
      const response = await axios.post(
        `${BASE_API_URL}/${endpoint}`,
        { ...formData },
        {
          headers: {
            "Content-Type": "application/json",
            auth: token,
          },
        }
      );
      if (response.data.status === 401) {
        handle500Error();
        return;
      }
      if (response.data.status === 400) {
        alert(response.data.data);
        return;
      }
      if (response.data && response.data.status === 201) {
        setSuccess(true);
        alert(successMessage);
        setFormData({ [passwordField]: "", [confirmField]: "" });
        onClose();
      } else {
        setError(errorMessage);
      }
    } catch (error) {
      console.error(`Error changing password (${title}):`, error);
      alert(error.response.data.message);
      setError(errorMessage);
    }
  };

  // Reset form state when modal closes
  const handleModalClose = () => {
    setFormData({ [passwordField]: "", [confirmField]: "" });
    setError(null);
    setSuccess(false);
    onClose();
  };

  return (
    <Modal
      style={customModalStyles}
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      // className="modal-content"
      overlayClassName="custom-overlay"
    >
      <h2>{title}</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>{title}</label>
          <input
            type="password"
            name={passwordField}
            value={formData[passwordField]}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Confirm {title}</label>
          <input
            type="text"
            name={confirmField}
            value={formData[confirmField]}
            onChange={handleChange}
            required
          />
        </div>

        <div className="bton-container">
          <Button type="submit">Update</Button>
          <Button type="button" onClick={handleModalClose}>
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ChangePasswordModal;
