import React from "react";
import ChangeLoginPass from "../../authentication/ChangeloginPass";
import withSideBarLayout from "../../sidebarlayout/withSideBarLayout";
import "./pass.css";
const LoginPass = () => {
  return (
    <div className="pass">
      <ChangeLoginPass />
    </div>
  );
};

export default withSideBarLayout(LoginPass);
