// TandC.jsx
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./Privacy.scss";

import { fetchTermsAndConditionsApi } from "./api/termsApi";
import { usePermission, useToken } from "../utils/useToken";
import AddTermsAndConditions from "./AddT&c";

const TandC = () => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { permission } = usePermission();
  const { token } = useToken();

  const fetchTermsAndConditions = async () => {
    setError("");
    setLoading(true);
    const { error, value: fetchedValue } = await fetchTermsAndConditionsApi(
      token
    );
    if (error) {
      setError(error);
    } else {
      setValue(fetchedValue);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchTermsAndConditions();
    }
  }, [token]);

  const loadingPlaceholder = "<p>Loading Terms and Conditions...</p>";

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "1%",
        }}
      >
        <h3>Terms and Conditions</h3>
        {permission === "write" && (
          <AddTermsAndConditions
            token={token}
            onUpdate={fetchTermsAndConditions}
            value={value}
            setValue={setValue}
          />
        )}
      </div>
      {error && <p className="error-message">{error}</p>}
      <div className="custom-editor">
        <ReactQuill
          value={loading ? loadingPlaceholder : value}
          readOnly={true}
          modules={{ toolbar: false }}
          formats={formats}
        />
      </div>
    </>
  );
};

export default TandC;

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];
